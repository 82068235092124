import TopHeader from '../../../components/header/TopHeader';
import SideHeader from '../../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import '../Finance.css';

function Omie() {

    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    {/* BOTÃO PARA VOLTAR */}
                    <button class="backButton" onClick={() => { navigate('/financeiro') }}>Voltar</button>
                </div>
                <div className="options">
                    <button class='option-btn' onClick={() => { navigate('/financeiro/omie/orcamento') }}>Orçamento</button>
                </div>
            </div>
        </div>
    )
}

export default Omie;