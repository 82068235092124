import { useState, useEffect } from 'react';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import Axios from 'axios';
import './Faq.css';
import QuestionModel from './QuestionModel';
import { useNavigate } from 'react-router-dom';


function Faq() {

    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    const [listQuestions, setListQuestions] = useState('');
    const [query, setQuery] = useState('');
    const getFilteredQuestions = (query, questions) => {
        if (!query) {
            return questions;
        }
        return questions.filter((question) => question.title.includes(query) || question.content.includes(query));
    };
    const filteredQuestions = getFilteredQuestions(query, Array.from(listQuestions));
   
    const handleInput = (e) => {
        setQuery(e.target.value);
    };


    useEffect(() => {
         Axios.get(process.env.REACT_APP_CLIENT_FAQ).then((response) => {
            setListQuestions(response.data);
        })
    }, []);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar-FAQ"}>
                <div className="FAQ_container">
                    <div className='SearchBar'>
                        <input placeholder='Pesquisar FAQ' type="text" onChange={handleInput} />
                    </div>
                    {(() => {
                        if (localStorage.getItem('department') === 'diretoria' || localStorage.getItem('department') === 'estrategiaeprodutos' || localStorage.getItem('role') === 'lider') {
                            return <button className='btnRegistrarFAQ' onClick={(e) => { navigate('/register/faq') }}>Registrar pergunta</button>
                        }
                    })()}
                    <div className='perguntas'>
                        {filteredQuestions.map((question, index) => {
                            return <QuestionModel
                                id={question.id}
                                title={question.title}
                                content={question.content}
                                media_link={question.media_link}></QuestionModel>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;