import { useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import './Profile.css';

function UpdateProfile() {

    var msg = null;
    var actualEmail = localStorage.getItem('email');
    const [navVisible, showNavbar] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [user, setUser] = useState({
        name: '',
        email: '',
        password: '',
        dt_nasc: ''
    });
    const navigate = useNavigate();
    const handleInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await Axios.put(process.env.REACT_APP_CLIENT_UPDATE_PROFILE, {
            actualEmail: actualEmail,
            password: user.password,
            name: user.name,
            dt_nasc: user.dt_nasc
        }).then((response) => {
            msg = response.data.msg;
            if (msg === 'userUpdated') alert('Dados atualizados!');
            navigate('/');
        })
    };
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="update_profile_page">
                    <h2>Editar perfil</h2>
                    <p><b>Nome: </b><input autofill='none' type="text" name="name" placeholder="Novo nome" value={user.name} onChange={handleInput} /></p>
                    <p><b>Senha: </b><input type={passwordShown ? 'text' : 'password'} name="password" placeholder="Nova senha" value={user.password} onChange={handleInput} /><div className='show_pwd_update' onClick={togglePasswordVisiblity}></div></p>

                    <p><b>Data de nascimento: </b><input type="date" name="dt_nasc" placeholder="Nova data de nascimento" value={user.dt_nasc} onChange={handleInput} /></p>
                    <button className='update_btn' onClick={handleSubmit}>Atualizar</button>
                    <button className='update_btn_back' onClick={() => { navigate('/profile') }}>Voltar</button>
                </div>
            </div>
        </div>
    )
}

export default UpdateProfile;