import logo_visa from '../../images/VisaCard16.svg';
import logo_master from '../../images/MasterCard16.png';
import logo_elo from '../../images/EloCard.png';
import logo_amex from '../../images/Amex.png';
import logo_hiper from '../../images/HiperCard.png';
import pos from '../../images/pos_android.png';
import valori from '../../images/valori.png';
import './PrecificadorPdf.css';


function PrecificadorPdf({taxasFinal, taxaAntecipacao, razaoSocial, check13x21}) {
    const taxaAntecipacaoCorrigida = taxaAntecipacao.replace(',', '.') / 100;

    return (
        <div>
            <div id="tb-p">
                <table className="table">
                    <tr className="top">
                        <img src={valori} className="lg-v" alt="Logo Valori"/>
                        <div className="foot">
                            Atendimento: 4000-1708 <br/>
                            valori.com.vc | contato@valori.com.vc <br/>
                        </div>
                    </tr>
                    <tr class="information">
                        <td class="razao">
                            <table>
                                <tr>
                                    <b>Razão Social: </b>{razaoSocial}
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <tr>
                                <td className="table_title">
                                    <br/>
                                    TABELA DE TAXAS - MDR S/ ANTECIPAÇÃO
                                </td>
                            </tr>
                        </td>
                    </tr>
                    <tr class="heading">
                        <td>Tipo (modalidade):</td>
                        <td><img src={logo_visa} className="vs" alt="Logo Visa" id="logo"/></td>
                        <td><img src={logo_master} className="ot" alt="Logo Mastercard" id="logo"/></td>
                        <td><img src={logo_elo} className="ot" alt="Logo Elo" id="logo"/></td>
                        <td><img src={logo_amex} className="ot" alt="Logo American Express" id="logo"/></td>
                        <td><img src={logo_hiper} className="ot" alt="Logo Hipercard" id="logo"/></td>
                        <td>Prazo de Recebimento</td>
                    </tr>
                    <tr className="item">
                        <td>Débito</td>
                        <td>{((taxasFinal.mediaDebitoVisa * 100 + 0.0001).toPrecision(3) < 1 ? (taxasFinal.mediaDebitoVisa * 100 + 0.0001).toPrecision(2).toString().replace(".", ",") : (taxasFinal.mediaDebitoVisa * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{((taxasFinal.mediaDebitoMaster * 100 + 0.0001).toPrecision(3) < 1 ? (taxasFinal.mediaDebitoMaster * 100 + 0.0001).toPrecision(2).toString().replace(".", ",") : (taxasFinal.mediaDebitoMaster * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{((taxasFinal.mediaDebitoElo * 100 + 0.0001).toPrecision(3) < 1 ? (taxasFinal.mediaDebitoElo * 100 + 0.0001).toPrecision(2).toString().replace(".", ",") : (taxasFinal.mediaDebitoElo * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>D + 1</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito à vista</td>
                        <td>{((taxasFinal.mediaAVistaVisa * 100 + 0.0001)).toPrecision(3) > 0 ? ((taxasFinal.mediaAVistaVisa * 100 + 0.0001)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.mediaAVistaMaster * 100 + 0.0001)).toPrecision(3) > 0 ? ((taxasFinal.mediaAVistaMaster * 100 + 0.0001)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.mediaAVistaElo * 100 + 0.0001)).toPrecision(3) > 0 ? ((taxasFinal.mediaAVistaElo * 100 + 0.0001)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.mediaAVistaAmex * 100 + 0.0001)).toPrecision(3) > 0 ? ((taxasFinal.mediaAVistaAmex * 100 + 0.0001)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.mediaAVistaHipercard * 100 + 0.0001)).toPrecision(3) > 0 ? ((taxasFinal.mediaAVistaHipercard * 100 + 0.0001)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>D + 30</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 2x - 6x</td>
                        <td>{((taxasFinal.media2x6Visa * 100)).toPrecision(3) > 0 ? ((taxasFinal.media2x6Visa * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media2x6Master * 100)).toPrecision(3) > 0 ? ((taxasFinal.media2x6Master * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media2x6Elo * 100)).toPrecision(3) > 0 ? ((taxasFinal.media2x6Elo * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media2x6Amex * 100)).toPrecision(3) > 0 ? ((taxasFinal.media2x6Amex * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media2x6Hipercard * 100)).toPrecision(3) > 0 ? ((taxasFinal.media2x6Hipercard * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>D + 30</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 7x - 12x</td>
                        <td>{((taxasFinal.media7x12Visa * 100)).toPrecision(3) > 0 ? ((taxasFinal.media7x12Visa * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media7x12Master * 100)).toPrecision(3) > 0 ? ((taxasFinal.media7x12Master * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media7x12Elo * 100)).toPrecision(3) > 0 ? ((taxasFinal.media7x12Elo * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media7x12Amex * 100)).toPrecision(3) > 0 ? ((taxasFinal.media7x12Amex * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>{((taxasFinal.media7x12Hipercard * 100)).toPrecision(3) > 0 ? ((taxasFinal.media7x12Hipercard * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                        <td>D + 30</td>
                    </tr>
                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 13x - 21x</td>
                            <td>{((taxasFinal.media13x21Visa * 100)).toPrecision(3) > 0 ? ((taxasFinal.media13x21Visa * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                            <td>{((taxasFinal.media13x21Master * 100)).toPrecision(3) > 0 ? ((taxasFinal.media13x21Master * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                            <td>{((taxasFinal.media13x21Elo * 100)).toPrecision(3) > 0 ? ((taxasFinal.media13x21Elo * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                            <td>{((taxasFinal.media13x21Amex * 100)).toPrecision(3) > 0 ? ((taxasFinal.media13x21Amex * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                            <td>{((taxasFinal.media13x21Hipercard * 100)).toPrecision(3) > 0 ? ((taxasFinal.media13x21Hipercard * 100)).toPrecision(3).toString().replace(".", ",") + '%' : '-'}</td>
                            <td>D + 30</td>
                        </tr>
                    )}
                </table>

                <div className="antecipacao">
                    <b>Taxa de
                        antecipação: </b> {(taxaAntecipacaoCorrigida * 100).toPrecision(3).replace(".", ",") + '%'}
                </div>
                <table className="table">

                    <tr>
                        <td colspan="5">
                            <tr>
                                <td className="table_title">
                                    <br/>
                                    TABELA DE TAXAS - MDR C/ ANTECIPAÇÃO
                                </td>
                            </tr>
                        </td>
                    </tr>

                    {/* Ignorar os aviso "==" pois utilizando 3 sinais, a comparação não funciona. */}

                    <tr class="heading">
                        <td>Tipo (modalidade):</td>
                        <td><img src={logo_visa} className="vs" alt="Logo Visa" id="logo"/></td>
                        <td><img src={logo_master} className="ot" alt="Logo Mastercard" id="logo"/></td>
                        <td><img src={logo_elo} className="ot" alt="Logo Elo" id="logo"/></td>
                        <td><img src={logo_amex} className="ot" alt="Logo American Express" id="logo"/></td>
                        <td><img src={logo_hiper} className="ot" alt="Logo Hipercard" id="logo"/></td>
                        <td>Prazo de Recebimento</td>
                    </tr>
                    {/* + 0.0001 para arredondar para cima com o toPrecision() */}
                    <tr className="item">
                        <td>Débito</td>
                        <td>{((taxasFinal.mediaDebitoVisa * 100 + 0.0001).toPrecision(3) < 1 ? (taxasFinal.mediaDebitoVisa * 100 + 0.0001).toPrecision(2).toString().replace(".", ",") : (taxasFinal.mediaDebitoVisa * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{((taxasFinal.mediaDebitoMaster * 100 + 0.0001).toPrecision(3) < 1 ? (taxasFinal.mediaDebitoMaster * 100 + 0.0001).toPrecision(2).toString().replace(".", ",") : (taxasFinal.mediaDebitoMaster * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{((taxasFinal.mediaDebitoElo * 100 + 0.0001).toPrecision(3) < 1 ? (taxasFinal.mediaDebitoElo * 100 + 0.0001).toPrecision(2).toString().replace(".", ",") : (taxasFinal.mediaDebitoElo * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 1'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito à vista</td>
                        <td>{((taxasFinal.mediaAVistaVisa + 1.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.mediaAVistaMaster + 1.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.mediaAVistaElo + 1.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.mediaAVistaAmex + 1.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.mediaAVistaHipercard + 1.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{(taxaAntecipacaoCorrigida > 0) ? 'D + 1' : 'D + 30'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 2x</td>
                        <td>{((taxasFinal.media2x6Visa + 1.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Master + 1.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Elo + 1.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Amex + 1.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Hipercard + 1.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{(taxaAntecipacaoCorrigida > 0) ? 'D + 1' : 'D + 60'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 3x</td>
                        <td>{((taxasFinal.media2x6Visa + 2.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Master + 2.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Elo + 2.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Amex + 2.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Hipercard + 2.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{(taxaAntecipacaoCorrigida > 0) ? 'D + 1' : 'D + 90'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 4x</td>
                        <td>{((taxasFinal.media2x6Visa + 2.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Master + 2.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Elo + 2.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Amex + 2.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Hipercard + 2.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{(taxaAntecipacaoCorrigida > 0) ? 'D + 1' : 'D + 120'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 5x</td>
                        <td>{((taxasFinal.media2x6Visa + 3.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Master + 3.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Elo + 3.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Amex + 3.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Hipercard + 3.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{(taxaAntecipacaoCorrigida > 0) ? 'D + 1' : 'D + 150'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 6x</td>
                        <td>{((taxasFinal.media2x6Visa + 3.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Master + 3.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Elo + 3.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Amex + 3.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{((taxasFinal.media2x6Hipercard + 3.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",") + '%'}</td>
                        <td>{(taxaAntecipacaoCorrigida > 0) ? 'D + 1' : 'D + 180'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 7x</td>
                        <td>{(((taxasFinal.media7x12Visa + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Master + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Elo + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Amex + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Hipercard + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 4.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 210'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 8x</td>
                        <td>{(((taxasFinal.media7x12Visa + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Master + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Elo + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Amex + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Hipercard + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 4.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 240'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 9x</td>
                        <td>{(((taxasFinal.media7x12Visa + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Master + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Elo + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Amex + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Hipercard + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 5.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 270'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 10x</td>
                        <td>{(((taxasFinal.media7x12Visa + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Master + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Elo + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Amex + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Hipercard + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 5.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 300'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 11x</td>
                        <td>{(((taxasFinal.media7x12Visa + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Master + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Elo + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Amex + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Hipercard + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 6.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 330'}</td>
                    </tr>
                    <tr className="item">
                        <td>Crédito 12x</td>
                        <td>{(((taxasFinal.media7x12Visa + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Master + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Elo + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Amex + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{(((taxasFinal.media7x12Hipercard + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 6.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                        <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 365'}</td>
                    </tr>
                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 13x</td>
                            <td>{(((taxasFinal.media7x12Visa + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Master + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Elo + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Amex + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Hipercard + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 7.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 210'}</td>
                        </tr>
                    )}
                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 14x</td>
                            <td>{(((taxasFinal.media7x12Visa + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Visa + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Visa + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Master + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Master + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Master + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Elo + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Elo + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Elo + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Amex + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Amex + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Amex + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media7x12Hipercard + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media7x12Hipercard + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media7x12Hipercard + 7.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 240'}</td>
                        </tr>
                    )}

                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 15x</td>
                            <td>{(((taxasFinal.media13x21Visa + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 8.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 270'}</td>
                        </tr>
                    )}
                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 16x</td>
                            <td>{(((taxasFinal.media13x21Visa + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 8.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 300'}</td>
                        </tr>
                    )}

                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 17x</td>
                            <td>{(((taxasFinal.media13x21Visa + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 9.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 330'}</td>
                        </tr>
                    )}

                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 18x</td>
                            <td>{(((taxasFinal.media13x21Visa + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 9.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 365'}</td>
                        </tr>
                    )}

                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 19x</td>
                            <td>{(((taxasFinal.media13x21Visa + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 10.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 365'}</td>
                        </tr>
                    )}

                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 20x</td>
                            <td>{(((taxasFinal.media13x21Visa + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 10.5 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 365'}</td>
                        </tr>
                    )}

                    {check13x21 && (
                        <tr className="item">
                            <td>Crédito 21x</td>
                            <td>{(((taxasFinal.media13x21Visa + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Visa + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Visa + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Master + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Master + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Master + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Elo + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Elo + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Elo + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Amex + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Amex + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Amex + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{(((taxasFinal.media13x21Hipercard + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3) > 10 ? ((taxasFinal.media13x21Hipercard + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(4).toString().replace(".", ",") : ((taxasFinal.media13x21Hipercard + 11.0 * taxaAntecipacaoCorrigida) * 100 + 0.0001).toPrecision(3).toString().replace(".", ",")) + '%'}</td>
                            <td>{taxaAntecipacaoCorrigida > 0 ? 'D + 1' : 'D + 365'}</td>
                        </tr>
                    )}
                </table>

                <div className="info">
                    * Aluguel de R$69,90/POS - Acima de R$15.000,00 transacionado, o aluguel é isentado
                </div>
            </div>
        </div>
    )

}

export default PrecificadorPdf;