import Axios from 'axios'
import {useState, useEffect} from 'react';
import './API.css';
import Loading from '../../../components/Loading';
import SideHeader from '../../../components/header/SideHeader';
import TopHeader from '../../../components/header/TopHeader';
import * as XLSX from 'xlsx';
import {BsDownload} from 'react-icons/bs';
import {useNavigate} from "react-router-dom";

// listar orçamentos
function Orcamento() {

    const navigate = useNavigate();
    const [listaOrcamento, setListaOrcamento] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [navVisible, showNavbar] = useState(false);
    const [consulta, setConsulta] = useState({
        anoConsulta: '',
        mesConsulta: '',
    });
    const [displayedCod, setDisplayedCod] = useState(['1', '2']);
    const handleReq = async () => {
        setIsLoading(true);
        await Axios.post(process.env.REACT_APP_CLIENT_LISTA_ORCAMENTOS_API, {
            nAno: Number(consulta.anoConsulta),
            nMes: Number(consulta.mesConsulta)
        }).then((response) => {
            response.data.ListaOrcamentos.map((item, index) => {
                setListaOrcamento(old => [...old, item])
            });
            setIsLoading(false);
        })
    };
    const handleInput = (e) => {
        setConsulta({...consulta, [e.target.name]: e.target.value})
    };
    const classNamer = (item) => {
        if (item.cCodCateg.length === 1) return 'table-highlight';
        else if (item.cCodCateg.length === 4) return 'table-sub';
    };
    const gerarArquivoExcel = () => {
        const data = [['Código', 'Descrição', 'Valor Previsto', 'Valor Realizado']];
        listaOrcamento.forEach(item => {
            const linha = [item.cCodCateg, item.cDesCateg, item.nValorPrevisto, item.nValorRealilzado];
            data.push(linha);
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Orçamento');

        const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        const blob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'orcamento.xlsx';

        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
    };

    function displayElement(item) {
        // Quando clicamos numa categoria
        if (item.cCodCateg.length === 1) {
            let nElements = 0;

            // Verificar quantos elementos filhos da categoria existem no array (subcategorias e itens de subcategorias)
            Array.from(displayedCod).forEach((e, index) => {
                if (e.startsWith(item.cCodCateg)) {
                    nElements++;
                }
            });

            // Caso não existam filhos no array
            if (nElements === 1) {
                Array.from(listaOrcamento).map((e, index) => {
                    if (e.cCodCateg.startsWith(item.cCodCateg) && e.cCodCateg.length === 4) {
                        setDisplayedCod(old => [...old, e.cCodCateg])
                    }
                })

                // Caso existam filhos no array
            } else if (nElements > 1) {
                setDisplayedCod(old => {
                    return old.filter(element => element.startsWith(item.cCodCateg) === false || element.length === 1)
                });
            }


            // Quando clicamos numa subcategoria
        } else if (item.cCodCateg.length === 4) {
            let nElements = 0;

            // Verificar quantos elementos filhos da categoria existem no array (subcategorias e itens de subcategorias)
            Array.from(displayedCod).forEach((e, index) => {
                if (e.startsWith(item.cCodCateg)) {
                    nElements++;
                }
            });

            // Caso não existam filhos no array
            if (nElements === 1) {
                Array.from(listaOrcamento).map((e, index) => {
                    if (e.cCodCateg.startsWith(item.cCodCateg) && e.cCodCateg.length === 7) {
                        setDisplayedCod(old => [...old, e.cCodCateg])
                    }
                })

                // Caso existam filhos no array
            } else if (nElements > 1) {
                setDisplayedCod(old => {
                    return old.filter(element => element.startsWith(item.cCodCateg) === false || element.length <= 4)
                });
            }

        }
    }

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    {/* BOTÃO PARA VOLTAR */}
                    <button class="backButton" onClick={() => {
                        navigate('/financeiro/omie')
                    }}>Voltar
                    </button>
                </div>
                <h2>Selecione o ano e o mês que deseja</h2>
                <div className='req-input'>
                    <input type="number" name="anoConsulta" placeholder='Ano' value={consulta.anoConsulta}
                           onChange={handleInput} min="2023" max="2025"></input>
                    <input type="number" name="mesConsulta" placeholder='Mês' value={consulta.mesConsulta}
                           onChange={handleInput} min="1" max="12"></input>
                </div>
                <button className='req-btn' onClick={handleReq}>Enviar requisição</button>
                <div>
                    {Array.from(listaOrcamento).length <= 0 ? <b/> :
                        <button className='download' onClick={gerarArquivoExcel}><BsDownload/></button>}
                    <div id="orcamentoContainer">
                        {isLoading ? <Loading/> : <table>
                            <tr className='table-header'>
                                <th>Código</th>
                                <th>Descrição</th>
                                <th>Previsto</th>
                                <th>Realizado</th>
                            </tr>
                            {Array.from(listaOrcamento).map((item, index) => {
                                if (Array.from(displayedCod).indexOf(item.cCodCateg) !== -1) {
                                    return (
                                        <tr className='table-content' key={index}>
                                            <td className={classNamer(item)} onClick={() => {
                                                displayElement(item)
                                            }}>{item.cCodCateg}</td>
                                            <td className={classNamer(item)} onClick={() => {
                                                displayElement(item)
                                            }}>{item.cDesCateg}</td>
                                            <td className={classNamer(item)} onClick={() => {
                                                displayElement(item)
                                            }}>{item.nValorPrevisto.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}</td>
                                            <td className={classNamer(item)} onClick={() => {
                                                displayElement(item)
                                            }}>{item.nValorRealilzado.toLocaleString('pt-BR', {
                                                style: 'currency',
                                                currency: 'BRL',
                                            })}</td>
                                        </tr>
                                    )
                                } else return null;
                            })}
                        </table>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Orcamento;