import './Home.css'
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import NewsModel from '../../components/NewsModel';
import { useNavigate } from 'react-router-dom';
import ImageSlider from '../../components/slider/ImageSlider';
import Loading from '../../components/Loading';

function Home() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [listNews, setListNews] = useState('');
    
    
    const slides = [
        { url: '../../images/slider01.jpg', title: 'Pesquisa Bem Estar', link: 'https://www.dash.valori.com.vc/bem-estar'},
        { url: '../../images/slider04.jpg', title: 'Fórmula PPV', link: ''},    
    ];

    useEffect( () => {
        setIsLoading(true);
        Axios.get(process.env.REACT_APP_CLIENT_NEWS).then((response) => {
            setListNews(response.data);
            setIsLoading(false);
        })
    }, []);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={navVisible ? "page page-with-navbar" : "page"}>
                <div className='dashboard'>
                    
                    <div className='imageSlider'>
                        <ImageSlider slides={slides} parentWidth={1150} />
                    </div>
                    <br></br>
                    <br></br>
                    <div className='homeContainer'>
                        {isLoading ? <Loading /> : <div className='newsContaienr'>
                            {Array.from(listNews).sort((a, b) => (a.dt_creation < b.dt_creation) ? 1 : ((b.dt_creation < a.dt_creation) ? -1 : 0)).map((news, index) => {
                                if (index <= 6) {
                                    return <NewsModel
                                        id={news.id}
                                        title={news.title}
                                        content={news.content}
                                        dt_creation={news.dt_creation}
                                        author={news.author}
                                        key={index}></NewsModel>
                                }
                            })}
                        </div>}
                        <button className='homeButton' onClick={() => { navigate('/news') }}>Mais notícias</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
