import SideHeader from "../../components/header/SideHeader";
import TopHeader from "../../components/header/TopHeader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function BemEstar() {

    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);

    return (
        <div>
            {/*<SideHeader visible={navVisible} show={showNavbar} /> */}
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/') }}>Voltar</button>
                </div>
                <h3>Caso tenha respondido, clique no botão acima</h3>
                <div className="h-form">
                    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd8-QqAucKqd8FT0B6_O8Kl1EvEL_nGE7dCT1xYMMuu9PCnJA/viewform?embedded=true" width="1000" height="800" frameborder="0" marginheight="0" marginwidth="0">Carregando…</iframe>
                </div>
            </div>
        </div >
    )
}
export default BemEstar;