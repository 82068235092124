import Axios from "axios";
import {useEffect, useState} from "react";
import './Profile.css';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import {useNavigate} from "react-router-dom";
import Loading from "../../components/Loading";
import {FiEdit} from 'react-icons/fi';
import {RiDeleteBinLine} from 'react-icons/ri';

function Profile() {

    const actualEmail = localStorage.getItem('email');
    const department = localStorage.getItem('department');
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({
        name: "",
        email: "",
        dt_nasc: "",
        department: ""
    });

    const formatDep = () => {
        if (userInfo.department === "estrategiaeprodutos") return "Estratégia e Produtos";
        else return userInfo.department.toUpperCase;
    }

    useEffect(() => {
        setIsLoading(true);
        Axios.get(process.env.REACT_APP_CLIENT_PROFILE, {
            params: {
                actualEmail: actualEmail
            }
        }).then((response) => {
            setUserInfo({
                ...userInfo,
                name: response.data[0].name, email: response.data[0].email,
                dt_nasc: response.data[0].dt_nasc, department: response.data[0].department
            });
            setIsLoading(false);
        })
    }, []);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                {isLoading ? <Loading/> : <div className="profile_page">
                    <p><b>Nome:</b> {userInfo.name}</p>
                    <p><b>E-mail:</b> {userInfo.email}</p>
                    <p><b>Data de nascimento:</b> {userInfo.dt_nasc.split('-').reverse().join('/')}</p>
                    <p><b>Departamento:</b> {formatDep()}</p>
                    <button onClick={() => {
                        navigate('/update/profile')
                    }}><FiEdit size={15}/> Editar Perfil
                    </button>
                    {department === 'estrategiaeprodutos' || department === 'financeiro' ? <button onClick={() => {
                        navigate('/delete/profile')
                    }}><RiDeleteBinLine size={15}/> Excluir Perfil</button> : <p></p>}
                </div>}
            </div>
        </div>
    )
}

export default Profile;
