import './Header.css';
import {useNavigate} from 'react-router-dom';
import {
    FaAngleRight,
    FaAngleLeft,
    FaNewspaper,
    FaSignOutAlt,
    FaBars,
    FaCalendarAlt,
    FaUserPlus,
} from 'react-icons/fa';
import {AiOutlineQuestionCircle, AiOutlineForm} from 'react-icons/ai';
import {HiReceiptTax} from 'react-icons/hi';
import {NavLink} from "react-router-dom";
import {TbPresentationAnalytics, TbBrandGoogleAnalytics, TbReportMoney} from 'react-icons/tb';
import {MdOutlineOndemandVideo} from 'react-icons/md';

function SideHeader({visible, show}) {
    const navigate = useNavigate();
    const department = localStorage.getItem('department');
    const role = localStorage.getItem('role');
    const logOut = () => {
        localStorage.clear();
        navigate('/login');
    };

    return (
        <div className='mobile-nav'>
            <button className='mobile-nav-btn' onClick={() => show(!visible)}>
                <FaBars size={20}/>
                <p className='paragraph'>Bem vindo ao ValoriDash, {localStorage.getItem('userName')}!</p>
            </button>
            <nav className={!visible ? 'navbar' : 'nav'}>
                <button type='button' className='nav-btn' onClick={() => show(!visible)}>
                    {!visible ? <FaAngleRight size={30}/> : <FaAngleLeft size={30}/>}
                </button>
                <div className='links nav-top'>
                    <NavLink className="logo" to="/">
                        <img className='nav_logo' src={require("../../images/logo_nav.png")} alt="logo"/>
                    </NavLink>
                    <NavLink to='/relatorios' className='nav-link'>
                        <TbBrandGoogleAnalytics color='white'/>
                        <span className='span'>Relatórios</span>
                    </NavLink>
                    <NavLink to="/calendar" className="nav-link">
                        <FaCalendarAlt color='white'/>
                        <span className='span'>Calendário </span>
                    </NavLink>
                    <NavLink to="/news" className="nav-link">
                        <FaNewspaper color='white'/>
                        <span className='span'>Notícias</span>
                    </NavLink>

                    <NavLink to="/academia" className="nav-link">
                        <MdOutlineOndemandVideo color='white'/>
                        <span className='span'>V. Academy</span>
                    </NavLink>

                    <NavLink to="/formularios" className="nav-link">
                        <AiOutlineForm color='white'/>
                        <span className='span'>Formulários</span>
                    </NavLink>

                    {/* Condição para esconder a opção do menu lateral */}
                    {department === 'estrategiaeprodutos' || department === 'diretoria' || department === 'financeiro' ?
                        <NavLink to="/financeiro" className="nav-link">
                            <TbReportMoney color='white'/>
                            <span className='span'>Financeiro</span>
                        </NavLink> : <p></p>}
                    {department === 'estrategiaeprodutos' || department === 'diretoria' ?
                        <NavLink to="/analyzes" className="nav-link">
                            <TbPresentationAnalytics color='white'/>
                            <span className='span'>Análises</span>
                        </NavLink> : <p></p>}
                    {department === 'estrategiaeprodutos' || department === 'diretoria' || department === 'operacional' || department === 'atendimento' || role === 'lider' ?
                        <NavLink to="/precificador" className="nav-link">
                            <HiReceiptTax color='white'/>
                            <span className='span'>Precificador</span>
                        </NavLink> : <p></p>}
                    {department === 'estrategiaeprodutos' || department === 'financeiro' ?
                        <NavLink to="/register/user" className="nav-link-adduser">
                            <FaUserPlus color='white'/>
                            <span className='span'>Registrar Usuário</span>
                        </NavLink> : <p></p>}

                    <NavLink to="/faq" className="nav-link-mobile">
                        <AiOutlineQuestionCircle color='white'/>
                        <span className='span'>FAQ</span>
                    </NavLink>
                    <NavLink to="/login" className='logoutButton' onClick={logOut}>
                        <FaSignOutAlt color='white'/>
                        <span className='span'>Sair</span>
                    </NavLink>
                </div>
            </nav>
        </div>
    )
}

export default SideHeader;