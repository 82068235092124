export const TPVData = [
    {
        id: 1,
        month: 'Janeiro',
        year: 2023,
        tpv: 87.2,
    },
    {
        id: 2,
        month: 'Fevereiro',
        tpv: 81.7,
        year: 2023,
    },
    {
        id: 3,
        month: 'Março',
        tpv: 94.5,
        year: 2023,
    },
    {
        id: 4,
        month: 'Abril',
        tpv: 93.0,
        year: 2023,
    },
    {
        id: 5,
        month: 'Maio',
        tpv: 98.3,
        year: 2023,
    },
    {
        id: 6,
        month: 'Junho',
        tpv: 98.5,
        year: 2023,

    },
    {
        id: 7,
        month: 'Julho',
        tpv: 104.0,
        year: 2023,
    },
    {
        id: 8,
        month: 'Agosto',
        tpv: 105.1,
        year: 2023,
    },
    {
        id: 9,
        month: 'Setembro',
        tpv: 108.7,
        year: 2023,
    },
    {
        id: 10,
        month: 'Outubro',
        tpv: 108.7,
        year: 2023,
    },
    {
        id: 11,
        month: 'Novembro',
        tpv: 112.4,
        year: 2023,
    },
    {
        id: 12,
        month: 'Dezembro',
        tpv: 139.4,
        year: 2023,
    },
    {
        id: 13,
        month: 'Janeiro',
        tpv: 99.9,
        year: 2024,
    },
];

export const TPVDataDaily = [
    {
        id: 1,
        month: 'Janeiro',
        average: 2.81,
        year: 2023,
    },
    {
        id: 2,
        month: 'Fevereiro',
        average: 2.91,
        year: 2023,
    },
    {
        id: 3,
        month: 'Março',
        average: 3.05,
        year: 2023,
    },
    {
        id: 4,
        month: 'Abril',
        average: 3.10,
        year: 2023,
    },
    {
        id: 5,
        month: 'Maio',
        average: 3.17,
        year: 2023,
    },
    {
        id: 6,
        month: 'Junho',
        average: 3.28,
        year: 2023,
    },
    {
        id: 7,
        month: 'Julho',
        average: 3.36,
        year: 2023,
    },
    {
        id: 8,
        month: 'Agosto',
        average: 3.39,
        year: 2023,
    },
    {
        id: 9,
        month: 'Setembro',
        average: 3.62,
        year: 2023,
    },
    {
        id: 10,
        month: 'Outubro',
        average: 3.51,
        year: 2023,
    },
    {
        id: 11,
        month: 'Novembro',
        average: 3.75,
        year: 2023,
    },
    {
        id: 12,
        month: 'Dezembro',
        average: 4.50,
        year: 2023,
    },
    {
        id: 13,
        month: 'Janeiro',
        average: 3.57,
        year: 2024,
    }
];

export const RevenueData = [
    {
        id: 1,
        month: 'Janeiro',
        revenue: 341.57,
        year: 2023,
    },
    {
        id: 2,
        month: 'Fevereiro',
        revenue: 359.62,
        year: 2023,
    },
    {
        id: 3,
        month: 'Março',
        revenue: 376.64,
        year: 2023,
    },
    {
        id: 4,
        month: 'Abril',
        revenue: 363.66,
        year: 2023,
    },
    {
        id: 5,
        month: 'Maio',
        revenue: 469.15,
        year: 2023,
    },
    {
        id: 6,
        month: 'Junho',
        revenue: 435.51,
        year: 2023,
    },
    {
        id: 7,
        month: 'Julho',
        revenue: 439.33,
        year: 2023,
    },
    {
        id: 8,
        month: 'Agosto',
        revenue: 514.95,
        year: 2023,
    },
    {
        id: 9,
        month: 'Setembro',
        revenue: 444.54,
        year: 2023,
    },
    {
        id: 10,
        month: 'Outubro',
        revenue: 500.22,
        year: 2023,
    },
    {
        id: 11,
        month: 'Novembro',
        revenue: 584.45,
        year: 2023,
    },
    {
        id: 12,
        month: 'Dezembro',
        revenue: 613.66,
        year: 2023,
    },
    {
        id: 13,
        month: 'Janeiro',
        revenue: 502.18,
        year: 2024,
    },
];

export const RevenueDailyData = [
    {
        id: 1,
        month: 'Janeiro',
        average: 11.01,
        year: 2023,
    },
    {
        id: 2,
        month: 'Fevereiro',
        average: 12.84,
        year: 2023,
    },
    {
        id: 3,
        month: 'Março',
        average: 12.55,
        year: 2023,
    },
    {
        id: 4,
        month: 'Abril',
        average: 12.12,
        year: 2023,
    },
    {
        id: 5,
        month: 'Maio',
        average: 15.13,
        year: 2023,
    },
    {
        id: 6,
        month: 'Junho',
        average: 14.52,
        year: 2023,
    },
    {
        id: 7,
        month: 'Julho',
        average: 16.27,
        year: 2023,
    },
    {
        id: 8,
        month: 'Agosto',
        average: 16.61,
        year: 2023,
    },
    {
        id: 9,
        month: 'Setembro',
        average: 14.82,
        year: 2023,
    },
    {
        id: 10,
        month: 'Outubro',
        average: 16.14,
        year: 2023,
    },
    {
        id: 11,
        month: 'Novembro',
        average: 19.49,
        year: 2023,
    },
    {
        id: 12,
        month: 'Dezembro',
        average: 19.80,
        year: 2023,
    },
    {
        id: 13,
        month: 'Janeiro',
        average: 17.93,
        year: 2024,
    },
];
