import './Register.css';
import {useState} from 'react';
import Axios from 'axios';
import {useNavigate} from 'react-router-dom';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import {RiTeamFill} from 'react-icons/ri';
import {FaUserAlt, FaLock, FaCalendarAlt} from 'react-icons/fa';
import {MdEmail} from 'react-icons/md';
import {AiOutlineEyeInvisible} from 'react-icons/ai';


function Register() {

    var msg = null;
    const [navVisible, showNavbar] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
        department: "atendimento",
        dt_nasc: ""
    });
    const navigate = useNavigate();
    const preventSubmit = (e) => {
        e.preventDefault();
    };
    const handleInput = (e) => {
        setUser({...user, [e.target.name]: e.target.value})
    };
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const handleSubmit = async () => {
        // Envia uma requisição para a url com os dados do user
        await Axios.post(process.env.REACT_APP_CLIENT_REGISTER, {
            email: user.email,
            password: user.password,
            name: user.name,
            department: user.department,
            dt_nasc: user.dt_nasc
        }).then((response) => {
            msg = response.data.msg;
            if (msg === 'invalidEmail') alert('E-mail inválido. Por favor insira um valor válido.');
            if (msg === 'invalidPassword') alert('Senha inválida. A sua senha precisa conter pelo menos uma letra maiúscula, uma letra minúscula, um numeral e um caractere especial!');
            if (msg === 'userAlreadyRegistered') alert('Usuário já está registrado.');
            if (msg === 'newUserAdded') {
                alert('Novo usuário cadastrado.');
                navigate('/');
            }
        })
    };

    return (
        <div id="addMain">
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <h1>CADASTRAR NOVO USUÁRIO</h1>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>

                <div id="addFormContainer">

                    {/* Formulário para cadastrar usuário */}
                    <form method="post" onSubmit={preventSubmit} id="addBox">
                        <div id='icon_container'>
                            <FaUserAlt size={20} color='rgb(183,158,135)'/>
                            <input type="text" name="name" placeholder="Nome" value={user.name} onChange={handleInput}
                                   required/>
                        </div>

                        <div id='icon_container'>
                            <FaCalendarAlt size={20} color='rgb(183,158,135)'/>
                            <input type="date" name="dt_nasc" placeholder="Data de nascimento" value={user.dt_nasc}
                                   onChange={handleInput} required/>
                        </div>

                        <div id='icon_container'>
                            <MdEmail size={20} color='rgb(183,158,135)'/>
                            <input type="email" name="email" placeholder="E-mail" value={user.email}
                                   onChange={handleInput} required/>
                        </div>
                        <div id='icon_container'>
                            <FaLock size={20} color='rgb(183,158,135)'/>
                            <input type={passwordShown ? 'text' : 'password'} name="password" placeholder="Senha"
                                   value={user.password} onChange={handleInput} required/>
                            <div className='show_pwd_add' onClick={togglePasswordVisiblity}>
                                <AiOutlineEyeInvisible size={25}/>
                            </div>
                        </div>

                        {/* Combo-box de departamento */}
                        <div id='icon_container'>
                            <RiTeamFill size={20} color='rgb(183,158,135)'/>
                            <select id='select-box' name="department" value={user.department} onChange={handleInput}>
                                <option value="atendimento">Atendimento</option>
                                <option value="comercial">Comercial</option>
                                <option value="diretoria">Diretoria</option>
                                <option value="estrategiaeprodutos">Estratégia e Produtos</option>
                                <option value="financeiro">Financeiro</option>
                                <option value="marketing">Marketing</option>
                                <option value="operacional">Operacional</option>
                                <option value="rh">RH</option>
                            </select>
                        </div>
                        <br/><br/>
                        <button className='btn_add' onClick={handleSubmit}>Cadastrar</button>
                    </form>
                </div>
            </div>
        </div>

    )
}

export default Register;
