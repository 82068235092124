import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import {useNavigate} from 'react-router-dom';
import LineChart from "../../components/LineChart";
import {TPVData, TPVDataDaily} from '../../resources/Data'
import {useEffect, useState} from "react";
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Axios from "axios";

function ChartUpdate() {
    const [consulta, setConsulta] = useState({
        year: 2024,
        month: 'Janeiro',
        tpv: '',
        media: '',
    });
    const [navVisible, showNavbar] = useState(false);

    const handleConsulta = (e) => {
        setConsulta({...consulta, [e.target.name]: e.target.value});
    };

    const handleSubmit = async () => {
        await Axios.put(process.env.REACT_APP_CLIENT_CHART_UPDATE, {
            year: consulta.year,
            month: consulta.month,
            tpv: consulta.tpv,
            media: consulta.media,
        }).then((response) => {
            alert('Dados Atualizados!')
        })
    };
    return (
        <div>
            {/*<SideHeader visible={navVisible} show={showNavbar}/>*/}
            {/*<TopHeader/>*/}

            <select name='year' value={consulta.year} onChange={handleConsulta} className='tpv-filter'>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
            </select>

            <select name='month' value={consulta.month} onChange={handleConsulta} className='tpv-filter'>
                <option value="Janeiro">Janeiro</option>
                <option value="Fevereiro">Fevereiro</option>
                <option value="Março">Março</option>
                <option value="Abril">Abril</option>
                <option value="Maio">Maio</option>
                <option value="Junho">Junho</option>
                <option value="Julho">Julho</option>
                <option value="Agosto">Agosto</option>
                <option value="Setembro">Setembro</option>
                <option value="Outubro">Outubro</option>
                <option value="Novembro">Novembro</option>
                <option value="Dezembro">Dezembro</option>
            </select>

            <input type="text" name="tpv" placeholder="TPV" value={consulta.tpv}
                   onChange={handleConsulta} required/>
            <input type="text" name="media" placeholder="Media" value={consulta.media}
                   onChange={handleConsulta} required/>

            <button className='update_btn' onClick={handleSubmit}>Atualizar</button>

        </div>
    )

}

export default ChartUpdate;
