import { useState } from "react";
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import './Analyzes.css';

function RegisterAnalysis() {

    var msg = null;

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [navVisible, showNavbar] = useState(false);
    const [analysis, setAnalysis] = useState({
        title: "",
        description: "",
        fileLink: "",
        date: ""
    });
    const navigate = useNavigate();
    const changeHandler = (event) => {
        setSelectedFile(event.currentTarget.files[0]);
        setIsFilePicked(true);
    };
    const preventSubmit = (e) => {
        e.preventDefault();
    };
    const handleInput = (e) => {
        setAnalysis({ ...analysis, [e.target.name]: e.target.value });
    };
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('file', selectedFile);

        // Enviar arquivo para ser
        Axios.post(process.env.REACT_APP_CLIENT_FILE_UPLOAD_BUILD, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(() => {
            Axios.post(process.env.REACT_APP_CLIENT_FILE_UPLOAD_PUBLIC, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then((response) => {
                msg = response.data.msg;
                Axios.post(process.env.REACT_APP_CLIENT_REGISTER_ANALYSIS, {
                    title: analysis.title,
                    description: analysis.description,
                    fileLink: msg,
                    date: analysis.date
                }).then((response) => {
                    if (response.data.msg === 'newAnalysisAdded') alert('Cadastro realizado com sucesso!');
                    navigate('/analyzes');
                })
            })
        })
    };

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={navVisible ? "page page-with-navbar" : "page"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/analyzes') }}>Voltar</button>
                </div>
                <div className="container_news">
                    <h2>Criar nova análise:</h2>
                    <form method='news' onSubmit={preventSubmit}>
                        <p>Título: </p>
                        <input type="text" name="title" placeholder="Título" value={analysis.title} onChange={handleInput} required />
                        <p>Descrição: </p>
                        <input type="text" name="description" placeholder="Descrição" value={analysis.description} onChange={handleInput} required />
                        <p>Arquivo: </p>
                        <input type="file" name="file" onChange={changeHandler} />
                        <p>Data:</p>
                        <input type="date" name="date" placeholder="Data" value={analysis.date} onChange={handleInput} required />
                        <br /><br />
                        <button className="news_btn" onClick={handleSubmit}>Cadastrar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RegisterAnalysis;