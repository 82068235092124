import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Academia.css';

function VideosComercial (){
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton_geral" onClick={() => { navigate('/academia') }}>Voltar</button>
                </div>
                <h1>Comercial</h1>
                <h3>01 - Introdução - Subir Cadastro</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/YzrY9MbE9a8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>02 - Elaboração de Contrato</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/TduOi72KdOc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>03 - Status Comercial</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/ax1_S_rLSoI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>04 - Subir Indicações</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/70zZnYkwBLo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
            </div>
        </div>
    )
}
export default VideosComercial;