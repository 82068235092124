import '../pages/news/News.css';
import Axios from 'axios';
import newsIcon from '../images/noticia.png';

function NewsModel(props) {

    var department = localStorage.getItem('department');
    const deleteNews = async () => {
       await Axios.delete(process.env.REACT_APP_CLIENT_DELETE_NEWS, {
            params: {
                content: props.content
            }
        }).then((response) => {
            let { msg } = response.data;
            if (msg === 'NewsDeleted') {
                alert('Notícia deletada com sucesso.')
                window.location.reload();
            }
        })
    };

    return (
        <div className="newsModelContainer">
            <div className='title_container'>
                <div className='title_img'>
                    <img src={newsIcon}></img>
                </div>
                <div>
                    <h1 id="newsModelTitle">{props.title}</h1>
                </div>
            </div>
            <p id="newsModelContent" >{props.content}</p>
            <p id="newsModelDate">{props.dt_creation.split('-').reverse().join('-')}</p>
            <p id="newsModelAuthor">{props.author}</p>
            {(() => {
                if (department === 'marketing' || department === 'estrategiaeprodutos') {
                    return <button id='del_btn' onClick={deleteNews}>Excluir</button>
                }
            })()}
        </div>
    )
}

export default NewsModel;