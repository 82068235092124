import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Relatorio.css';

function Diretoria() {

    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    
    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/relatorios') }}>Voltar</button>
                </div>
                <div className="optionsButtonContainer">
                    <button class="analyzesButton" onClick={() => { navigate('/analyzes') }}>Análises</button>
                </div>
                <br />
                <h1 className='titulo_dash'>Volumetria Geral Diretoria</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Volumetria Geral Diretoria" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiNTljZjljN2MtMDgwZi00YTUzLWI3NDItMTY3NTJlMzg0Y2VjIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameBorder='0' allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}

export default Diretoria;