import './Faq.css';

function QuestionModel(props) {

    return (
        <div className="FAQ_container">
            <h1 id="">{props.title}</h1>
            <p className="questionModelContent" >{props.content}</p>
            {(() => {
                if(props.media_link != null)
                return <a id="" href={props.media_link} target='_blank'>Clique para acessar</a>
            })()}
        </div>
    )

}

export default QuestionModel;