import { Navigate } from "react-router-dom";
import departmentAuthentication from "../authentication/departmentAuthentication";
import tokenAuthentication from "../authentication/tokenAuthentication";

export const PrivateRouteComercial = ({ children }) => {

    const authenticator = localStorage.getItem('department');

    if (departmentAuthentication() && tokenAuthentication()) {
        if (authenticator === 'comercial' || authenticator === 'estrategiaeprodutos' || authenticator === 'diretoria') {
            return children;
        }
    };

    alert('Você não tem permissão para acessar essa área!');
    return <Navigate to="/" />
}


