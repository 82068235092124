import { useState, React } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import loginLogo from '../../images/loginLogo.png';
import { MdEmail } from 'react-icons/md';

function ConfirmEmail() {

    const [userEmail, setUserEmail] = useState({
        email: "",
    });
    const navigate = useNavigate();
    const handleInput = (e) => {
        setUserEmail({ ...userEmail, [e.target.name]: e.target.value });
    };
    const preventSubmit = (e) => {
        e.preventDefault();
    };
    const handleSubmit = async () => {
        await Axios.get(process.env.REACT_APP_CLIENT_SENDEMAIL, {
            params: {
                actualEmail: userEmail.email
            }
        }).then((response) => {
            if (response.data.msg === 'emailSent') {
                alert('Sua nova senha temporária foi enviada para seu email. Não esqueça de trocar sua senha após fazer o login!');
                navigate('/login');
            }
        })
    };

    return (
        <div id="loginMain">
            <img src={loginLogo} id='login_logo' />
            <div id="loginFormContainer">
                <form method="post" onSubmit={preventSubmit} id="textbox" >

                    <p>Confirme seu email para receber uma senha temporária</p>

                    <div id='icon_container'>
                        <MdEmail color='rgb(183,158,135)'/>
                        <input type="email" name="email" placeholder="E-mail" value={userEmail.email} onChange={handleInput} required />
                    </div>
                    <br /><br />
                    <button className='btn_entrar' onClick={handleSubmit}>Enviar</button>

                </form>
            </div>
        </div>
    )
}
export default ConfirmEmail;