import { useState } from "react";
import './Faq.css';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';

function RegisterQuestion() {

    var msg = null;
    const [navVisible, showNavbar] = useState(false);
    const [question, setQuestion] = useState({
        title: "",
        content: ""
    });
    const navigate = useNavigate();
    const preventSubmit = (e) => {
        e.preventDefault();
    };
    const handleInput = (e) => {
        setQuestion({ ...question, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await Axios.post(process.env.REACT_APP_CLIENT_REGISTER_FAQ, {
            title: question.title,
            content: question.content
        }).then((response) => {
            msg = response.data.msg;
            if (msg === 'questionAlreadyRegistered') alert('Pergunta já está registrada.');
            if (msg === 'newQuestionAdded') {
                alert('Nova pergunta cadastrada.');
                navigate('/faq');
            }
        })
    };

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="container_news">
                    <h2>Criar nova pergunta:</h2>
                    <form method='news' onSubmit={preventSubmit}>
                        <p>Título:</p>
                        <input type="text" name="title" placeholder="Título" value={question.title} onChange={handleInput} required />
                        <p>Conteúdo:</p>
                        <textarea required rows="15" cols="40" name="content" value={question.content} onChange={handleInput}>Escreva a resposta aqui</textarea>
                        <br /><br />
                        <button className="news_btn" onClick={handleSubmit}>Cadastrar</button>
                        <button className="news_btn_back" onClick={(e) => { navigate('/faq') }}>Voltar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RegisterQuestion;