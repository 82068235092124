import SideHeader from "../../components/header/SideHeader";
import TopHeader from "../../components/header/TopHeader";
import PrecificadorPdf from "../../components/precificador/PrecificadorPdf";
import {BiSearch} from 'react-icons/bi';
import {BsDownload} from 'react-icons/bs';
import {useEffect, useState} from "react";
import Axios from 'axios';
import Loading from "../../components/Loading";
import html2pdf from 'html2pdf.js';
import logo_visa from '../../images/VisaCard16.svg';
import logo_master from '../../images/MasterCard16.png';
import logo_elo from '../../images/EloCard.png';
import logo_amex from '../../images/Amex.png';
import logo_hiper from '../../images/HiperCard.png';
import './Precificador.css';
import {useNavigate} from "react-router-dom";
import Speedometer from "../../components/Speedometer";
import {alertaAlcada, alertaCampoObrigatorioLivre, clockFormater} from "./utils";

function PrecificadorNovoLivre() {

    const navigate = useNavigate();
    const [consulta, setConsulta] = useState({
        cnpj: '',
        mcc: '',
        cidade: '',
        bairro: '',
        tpv: 50000,
        taxaAntecipacao: '1.50',
        percentualDebito: 20,
        percentualAVista: 20,
        percentual2x6: 20,
        percentual7x12: 20,
        percentual13x21: 20,
        noCusto: false,
        eCommerce: false
    });
    const [taxasInput, setTaxasInput] = useState({
        mediaDebitoMaster: '',
        mediaAVistaMaster: '',
        media2x6Master: '',
        media7x12Master: '',
        media13x21Master: '',
        mediaDebitoVisa: '',
        mediaAVistaVisa: '',
        media2x6Visa: '',
        media7x12Visa: '',
        media13x21Visa: '',
        mediaDebitoElo: '',
        mediaAVistaElo: '',
        media2x6Elo: '',
        media7x12Elo: '',
        media13x21Elo: '',
        mediaAVistaAmex: '',
        media2x6Amex: '',
        media7x12Amex: '',
        media13x21Amex: '',
        mediaAVistaHipercard: '',
        media2x6Hipercard: '',
        media7x12Hipercard: '',
        media13x21Hipercard: '',
    });
    const [taxasFinal, setTaxasFinal] = useState([]);
    const [mccArray, setMccArray] = useState([]);
    const [selectOptionCidade, setSelectOptionCidade] = useState([]);
    const [selectOptionBairro, setSelectOptionBairro] = useState([]);
    const [taxasOriginal, setTaxasOriginal] = useState([]);
    const [taxasMaximas, setTaxasMaximas] = useState([]);
    const [taxasMinimas, setTaxasMininas] = useState([]);
    const [propriedade, setPropriedade] = useState('mediaDebitoMaster');
    const [bandeira, setBandeira] = useState('Master');
    const [tipoPagamento, setTipoPagamento] = useState('Debito');
    const [percentualTotal, setPercentualTotal] = useState("0");
    const [navVisible, showNavbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [razaoSocial, setRazaoSocial] = useState('');
    const [spread, setSpread] = useState('');
    const [taxaAntecipacaoPdf, setTaxaAntecipacaoPdf] = useState('');
    const [mccPdf, setMccPdf] = useState('');
    const [check13x21, setCheck13x21] = useState(true);

    const userDepartment = localStorage.getItem('department');

    const handleInput = (e) => {
        setConsulta({...consulta, [e.target.name]: e.target.value})
    };

    const handle13x21 = (e) => {
        const consultaTemp = {...consulta};
        if (check13x21) {
            setCheck13x21(false);
            consultaTemp.percentualDebito = 25;
            consultaTemp.percentualAVista = 25;
            consultaTemp.percentual2x6 = 25;
            consultaTemp.percentual7x12 = 25;
            consultaTemp.percentual13x21 = 0;
            setConsulta(consultaTemp);
        } else if (!check13x21) {
            setCheck13x21(true)
            consultaTemp.percentualDebito = 20;
            consultaTemp.percentualAVista = 20;
            consultaTemp.percentual2x6 = 20;
            consultaTemp.percentual7x12 = 20;
            consultaTemp.percentual13x21 = 20;
            setConsulta(consultaTemp);
        }
        setCheck13x21(e.target.checked);
    }

    const fixPercentual = (e) => {
        const consultaPercent = {...consulta};
        if (consultaPercent.percentualDebito + consultaPercent.percentualAVista + consultaPercent.percentual2x6 + consultaPercent.percentual7x12 + consultaPercent.percentual13x21 < 100) {
            if (check13x21) {
                consultaPercent.percentualDebito = 20;
                consultaPercent.percentualAVista = 20;
                consultaPercent.percentual2x6 = 20;
                consultaPercent.percentual7x12 = 20;
                consultaPercent.percentual13x21 = 20;
                setConsulta(consultaPercent);
            } else {
                consultaPercent.percentualDebito = 25;
                consultaPercent.percentualAVista = 25;
                consultaPercent.percentual2x6 = 25;
                consultaPercent.percentual7x12 = 25;
                consultaPercent.percentual13x21 = 0;
                setConsulta(consultaPercent);
            }
        }
    }

    const handleInputTaxas = (e) => {
        setTaxasInput({...taxasInput, [e.target.name]: e.target.value})
    };

    const handleNoCusto = (e) => {
        if (e.target.checked) setConsulta({...consulta, noCusto: true});
        else if (!e.target.checked) setConsulta({...consulta, noCusto: false})
    };

    const handleECommerce = (e) => {
        if (e.target.checked) setConsulta({...consulta, eCommerce: true});
        else if (!e.target.checked) setConsulta({...consulta,eCommerce: false});
    }

    const inputBandeira = (e) => {
        let valor = e.target.value;
        setBandeira(valor);
    };

    const inputTipoPagamento = (e) => {
        let valor = e.target.value;
        setTipoPagamento(valor);
    };

    /* Consulta o CNPJ para descobrir a lista de CNAE e em seguida, descobre os MCC's associados para calcular qual o melhor MCC */
    const consultarCNPJ = async () => {
        setIsLoading(true);
        await Axios.post(process.env.REACT_APP_CLIENT_GET_CNAE, {
            cnpj: consulta.cnpj,
        }).then(async (response) => {
            if (response.data.msg === 'CNPJ invalido') {
                alert('CNPJ inválido!');
                setIsLoading(false);
                return -1;
            }

            let empresa = response.data;
            setRazaoSocial(empresa.razaoSocial);

            // Transformar em '0' se a taxa de antecipação for uma string vazia
            if (consulta.taxaAntecipacao === '') {
                var tempConsulta = {...consulta};
                tempConsulta.taxaAntecipacao = '0';
                setConsulta(tempConsulta);
            }

            await Axios.post(process.env.REACT_APP_CLIENT_GET_MCC, {
                cnaes: empresa.cnaes
            }).then((response) => {
                let mccArray = response.data;
                setMccArray(mccArray);
            })
            setIsLoading(false);
        });
    };

    /* Calcula as taxas e retorna a tabela */
    const gerarTaxas = async () => {
        setIsLoading(true);
        try {
            const response = await Axios.post(process.env.REACT_APP_CLIENT_GET_TAXES_LVIRE, {
                mcc: consulta.mcc,
                cidade: consulta.cidade,
                bairro: consulta.bairro,
                taxasFinal: taxasInput,
                taxaAntecipacao: consulta.taxaAntecipacao,
                percentualDebito: consulta.percentualDebito,
                percentualAVista: consulta.percentualAVista,
                percentual2x6: consulta.percentual2x6,
                percentual7x12: consulta.percentual7x12,
                percentual13x21: consulta.percentual13x21,
                noCusto: consulta.noCusto,
                eCommerce: consulta.eCommerce
            });

            setSpread(response.data[0].spread);
            setTaxasOriginal(response.data[1].taxasBase);
            setTaxasFinal(response.data[2].taxasFinal);
            setTaxasMaximas(response.data[3].taxasMaximas);
            setTaxasMininas(response.data[4].taxasMinimas);
            setTaxaAntecipacaoPdf(consulta.taxaAntecipacao);
            setMccPdf(consulta.mcc);
        } catch (error) {
            console.log(error + '\nErro ao gerar a taxa.');
            setSpread('');
            setTaxasOriginal([]);
            setTaxasFinal([]);
            setTaxasMaximas([]);
            setTaxasMininas([]);
            setTaxaAntecipacaoPdf('');

            alert('Erro ao gerar a taxa. ' + error.response.data.msg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setSpread('');
        setTaxasOriginal([]);
        setTaxasFinal([]);
        setTaxasMaximas([]);
        setTaxasMininas([]);
        setTaxaAntecipacaoPdf('');
    }, [check13x21, consulta.noCusto, consulta.eCommerce]);

    useEffect(() => {
        setIsLoading(true);
        Axios.get(process.env.REACT_APP_CLIENT_GET_CIDADE).then((response) => {
            setSelectOptionCidade(response.data);
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        setIsLoading(true);
        Axios.post(process.env.REACT_APP_CLIENT_GET_BAIRRO, {
            cidade: consulta.cidade
        }).then((response) => {
            setSelectOptionBairro(response.data);
            setIsLoading(false);
        })
    }, [consulta.cidade]);

    useEffect(() => {
        let percentualTotal = parseInt(consulta.percentualDebito) +
            parseInt(consulta.percentualAVista) +
            parseInt(consulta.percentual2x6) +
            parseInt(consulta.percentual7x12) +
            parseInt(consulta.percentual13x21)
        ;
        if (isNaN(percentualTotal)) setPercentualTotal(0);
        else setPercentualTotal(percentualTotal);
    }, [
        consulta.percentualDebito,
        consulta.percentualAVista,
        consulta.percentual2x6,
        consulta.percentual7x12,
        consulta.percentual13x21]);

    useEffect(() => {
        let prop = "media" + tipoPagamento + bandeira;
        setPropriedade(prop);
    }, [tipoPagamento, bandeira]);


    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>

            <div className={!navVisible ? "pag" : "pag pag-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => {
                        navigate('/precificador')
                    }}>Voltar
                    </button>
                </div>

                {/* Input de dados para consulta */}
                <div className="data-precificador-perc">
                    <table className="tb-prec">
                        <tr>
                            <td>CNPJ</td>
                            <td>MCC</td>
                            <td>Cidade</td>
                            <td>Bairro</td>
                            <td>TPV</td>
                            <td>Antecipação</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" name="cnpj" value={consulta.cnpj} onChange={handleInput} title="CNPJ"
                                       required id="cnpj"/>
                            </td>
                            <td>
                                <input type="text" name="mcc" value={consulta.mcc} onChange={handleInput} title="MCC"
                                       id="mcc" required></input>
                            </td>
                            <td>
                                <select name="cidade" onChange={handleInput} className="ct">
                                    <option value=""></option>
                                    {Array.from(selectOptionCidade).map((option, index) => {
                                        return <option value={option.CIDADE} key={index}>{option.CIDADE}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="bairro" onChange={handleInput} className="ngbh">
                                    <option value="" selected disabled hidden></option>
                                    {Array.from(selectOptionBairro).map((option, index) => {
                                        return <option value={option.BAIRRO} key={index}>{option.BAIRRO}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <input type="text" name="tpv" value={consulta.tpv} onChange={handleInput} title="TPV"
                                       id="tpv" required/>
                            </td>
                            <td><input type="text" name="taxaAntecipacao" value={consulta.taxaAntecipacao}
                                       onChange={handleInput} title="TAXA DE ANTECIPAÇÃO"/>
                            </td>
                        </tr>
                    </table>

                </div>
                <div className="data-precificador-perc">
                    <table className="tb-prec">
                        <tr>
                            <td>% Débito</td>
                            <td>% À vista</td>
                            <td>% 2x - 6x</td>
                            <td>% 7x - 12x</td>
                            {check13x21 && (
                                <td>% 13x - 21x</td>
                            )}
                            <td>13x - 21x</td>
                            {(userDepartment === 'diretoria' || userDepartment === 'estrategiaeprodutos') && (
                                <td>Calcular no custo</td>
                            )}
                            <td>E-Commerce</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" name="percentualDebito" placeholder="% Débito"
                                       value={consulta.percentualDebito} onChange={handleInput} title="% DÉBITO"
                                       required/>
                            </td>
                            <td>
                                <input type="text" name="percentualAVista" placeholder="% A vista"
                                       value={consulta.percentualAVista} onChange={handleInput} title="% A VISTA"
                                       required/>
                            </td>
                            <td>
                                <input type="text" name="percentual2x6" placeholder="% 2 x 6 vezes"
                                       value={consulta.percentual2x6} onChange={handleInput} title="% 2 X 6 VEZES"
                                       required/>
                            </td>
                            <td>
                                <input type="text" name="percentual7x12" placeholder="% 7 x 12 vezes"
                                       value={consulta.percentual7x12} onChange={handleInput} title="% 7 X 12 VEZES"
                                       required/>
                            </td>
                            {check13x21 && (
                                <td>
                                    <input type="text" name="percentual13x21" placeholder="% 13 x 21 vezes"
                                           value={consulta.percentual13x21} onChange={handleInput}
                                           title="% 13 X 21 VEZES"
                                           required/>
                                </td>
                            )}
                            <td className={"13x21"}><input
                                type="checkbox" name="checkbox13x21" placeholder="% 13 x 21 vezes"
                                checked={check13x21} onChange={handle13x21} title="13x21"
                            />
                            </td>
                            {(userDepartment === 'diretoria' || userDepartment === 'estrategiaeprodutos') && (
                                <td>
                                    <input type="checkbox"
                                           name="noCusto" checked={consulta.noCusto} onChange={handleNoCusto}/>
                                </td>
                            )}
                            <td>
                                <input
                                    type="checkbox"
                                    name="eCommerce"
                                    checked={consulta.eCommerce}
                                    onChange={handleECommerce}

                                />
                            </td>
                            <td>
                                <p
                                    className={percentualTotal < 100 || percentualTotal > 100 ? "tt-p-red" : "tt-p"}>Percentual
                                    total: {percentualTotal}%</p>
                            </td>
                        </tr>
                    </table>
                </div>

                <p className="subt">* Taxas com spreads baixos serão enviadas aos líderes dos setores para aprovação</p>
                <p className="subt">* Caso esteja usando o celular, use o modo horizontal para gerar o PDF </p>

                <div className="btn-prec-livre">
                    <button onClick={consultarCNPJ}>Consultar CNPJ<BiSearch/></button>
                    <button onClick={() => {
                        alertaCampoObrigatorioLivre(consulta, gerarTaxas)
                    }}>Consultar Taxas <BiSearch/></button>
                    <button onClick={() => {
                        alertaAlcada(consulta, razaoSocial, spread)
                    }}
                            disabled={taxasFinal.length === 0 || isLoading || (consulta.tpv <= 30000 && spread < 0.009) ? true : false}>Baixar
                        PDF <BsDownload/></button>
                    <a href="https://www.monitorabecs.com.br/mcc/busca/index.aspx" target="__blank">Clique aqui para
                        consultar o CNPJ na abcs</a>
                </div>
                {isLoading ? <Loading/> : <div>
                    {/* Tabela de taxas preenchida em cada modalidade de pagamento via cartão com antecipação */}

                    {spread ? <div>
                        <div>
                            {consulta.tpv <= 30000 && spread < 0.009 ?
                                <h2>Spread inválido para esse TPV. Para um TPV de R$ 30.000,00 ou menos, o spread mínimo
                                    é de 0.9%</h2> : <div></div>}
                            <div className="cinfo">
                                <p>
                                    <b> MCC utilizado: </b>{mccPdf} <br/>
                                    <b> Spread: </b>{(spread * 100).toPrecision(3).toString().replace(".", ",")}%
                                </p>
                            </div>
                            <PrecificadorPdf
                                taxaAntecipacao={taxaAntecipacaoPdf}
                                taxasFinal={taxasFinal}
                                razaoSocial={razaoSocial}
                                check13x21={check13x21}
                            />

                            <table className="tb-prec">
                                <tr>
                                    <td>Bandeira</td>
                                    <td>Tipo Pagamento</td>
                                </tr>
                                <tr>
                                    <td><select onChange={inputBandeira}>
                                        <option name="Mastercard" value="Master">Mastercard</option>
                                        <option name="Visa" value="Visa">Visa</option>
                                        <option name="Elo" value="Elo">Elo</option>
                                    </select></td>
                                    <td><select onChange={inputTipoPagamento}>
                                        <option name="Débito" value="Debito">Débito</option>
                                        <option name="A vista" value="AVista">A vista</option>
                                        <option name="2x" value="2x6-2x">2x</option>
                                        <option name="3x" value="2x6-3x">3x</option>
                                        <option name="4x" value="2x6-4x">4x</option>
                                        <option name="5x" value="2x6-5x">5x</option>
                                        <option name="6x" value="2x6-6x">6x</option>
                                        <option name="7x" value="7x12-7x">7x</option>
                                        <option name="8x" value="7x12-8x">8x</option>
                                        <option name="9x" value="7x12-9x">9x</option>
                                        <option name="10x" value="7x12-10x">10x</option>
                                        <option name="11x" value="7x12-11x">11x</option>
                                        <option name="12x" value="7x12-12x">12x</option>
                                        <option name="13x" value="13x21-13x">13x</option>
                                        <option name="14x" value="13x21-14x">14x</option>
                                        <option name="15x" value="13x21-15x">15x</option>
                                        <option name="16x" value="13x21-16x">16x</option>
                                        <option name="17x" value="13x21-17x">17x</option>
                                        <option name="18x" value="13x21-18x">18x</option>
                                        <option name="19x" value="13x21-19x">19x</option>
                                        <option name="20x" value="13x21-20x">20x</option>
                                        <option name="21x" value="13x21-21x">21x</option>
                                    </select></td>
                                </tr>
                            </table>
                            <div className="relogio">
                                <Speedometer
                                    maxValue={clockFormater(taxasMaximas, propriedade, taxaAntecipacaoPdf)}
                                    minValue={clockFormater(taxasMinimas, propriedade, taxaAntecipacaoPdf)}
                                    avgValue={clockFormater(taxasOriginal, propriedade, taxaAntecipacaoPdf)}
                                />
                            </div>
                        </div>
                    </div> : <div>
                    </div>
                    }

                    { /* Tabela de preenchimento manual do comercial com as taxas desejadas. Antes de gerar a automática, aparece em primeiro plano
                    e após gerada, vai para o final da página */}

                    <div className="tb-livre">
                        <table className="table-livre">
                            <tr class="heading">
                                <td>Tipo (modalidade):</td>
                                <td><img src={logo_visa} className="vs" alt="Logo Visa" id="logo"/></td>
                                <td><img src={logo_master} className="ot" alt="Logo Mastercard" id="logo"/></td>
                                <td><img src={logo_elo} className="ot" alt="Logo Elo" id="logo"/></td>
                                <td><img src={logo_amex} className="ot" alt="Logo American Express" id="logo"/></td>
                                <td><img src={logo_hiper} className="ot" alt="Logo Hipercard" id="logo"/></td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" placeholder="" value="Débito" onChange={handleInputTaxas}
                                           title="" readOnly disabled className="ip-tx"/>
                                </td>
                                <td>
                                    <input type="text" name="mediaDebitoVisa" placeholder=""
                                           value={taxasInput.mediaDebitoVisa} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="mediaDebitoMaster" placeholder=""
                                           value={taxasInput.mediaDebitoMaster} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="mediaDebitoElo" placeholder=""
                                           value={taxasInput.mediaDebitoElo} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" placeholder="" value="/" title="" readOnly disabled
                                           className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" placeholder="" value="/" title="" readOnly disabled
                                           className="ip-tx" id='tax'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" placeholder="" value="Credito à Vista"
                                           onChange={handleInputTaxas} title="" readOnly disabled className="ip-tx"/>
                                </td>
                                <td>
                                    <input type="text" name="mediaAVistaVisa" placeholder=""
                                           value={taxasInput.mediaAVistaVisa} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="mediaAVistaMaster" placeholder=""
                                           value={taxasInput.mediaAVistaMaster} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="mediaAVistaElo" placeholder=""
                                           value={taxasInput.mediaAVistaElo} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="mediaAVistaAmex" placeholder=""
                                           value={taxasInput.mediaAVistaAmex} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="mediaAVistaHipercard" placeholder=""
                                           value={taxasInput.mediaAVistaHipercard} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" placeholder="" value="Crédito 2x - 6x"
                                           onChange={handleInputTaxas} title="" readOnly disabled className="ip-tx"/>
                                </td>
                                <td>
                                    <input type="text" name="media2x6Visa" placeholder=""
                                           value={taxasInput.media2x6Visa} onChange={handleInputTaxas} title="" required
                                           className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media2x6Master" placeholder=""
                                           value={taxasInput.media2x6Master} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media2x6Elo" placeholder="" value={taxasInput.media2x6Elo}
                                           onChange={handleInputTaxas} title="" required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media2x6Amex" placeholder=""
                                           value={taxasInput.media2x6Amex} onChange={handleInputTaxas} title="" required
                                           className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media2x6Hipercard" placeholder=""
                                           value={taxasInput.media2x6Hipercard} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" placeholder="" value="Crédito 7x - 12x"
                                           onChange={handleInputTaxas} title="" readOnly disabled className="ip-tx"/>
                                </td>
                                <td>
                                    <input type="text" name="media7x12Visa" placeholder=""
                                           value={taxasInput.media7x12Visa} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media7x12Master" placeholder=""
                                           value={taxasInput.media7x12Master} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media7x12Elo" placeholder=""
                                           value={taxasInput.media7x12Elo} onChange={handleInputTaxas} title="" required
                                           className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media7x12Amex" placeholder=""
                                           value={taxasInput.media7x12Amex} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                                <td>
                                    <input type="text" name="media7x12Hipercard" placeholder=""
                                           value={taxasInput.media7x12Hipercard} onChange={handleInputTaxas} title=""
                                           required className="ip-tx" id='tax'/>
                                </td>
                            </tr>
                            {check13x21 && (
                                <tr>
                                    <td>
                                        <input type="text" placeholder="" value="Crédito 13x - 21x"
                                               onChange={handleInputTaxas} title="" readOnly disabled
                                               className="ip-tx"/>
                                    </td>
                                    <td>
                                        <input type="text" name="media13x21Visa" placeholder=""
                                               value={taxasInput.media13x21Visa} onChange={handleInputTaxas} title=""
                                               required className="ip-tx" id='tax'/>
                                    </td>
                                    <td>
                                        <input type="text" name="media13x21Master" placeholder=""
                                               value={taxasInput.media13x21Master} onChange={handleInputTaxas} title=""
                                               required className="ip-tx" id='tax'/>
                                    </td>
                                    <td>
                                        <input type="text" name="media13x21Elo" placeholder=""
                                               value={taxasInput.media13x21Elo} onChange={handleInputTaxas} title=""
                                               required
                                               className="ip-tx" id='tax'/>
                                    </td>
                                    <td>
                                        <input type="text" name="media13x21Amex" placeholder=""
                                               value={taxasInput.media13x21Amex} onChange={handleInputTaxas} title=""
                                               required className="ip-tx" id='tax'/>
                                    </td>
                                    <td>
                                        <input type="text" name="media13x21Hipercard" placeholder=""
                                               value={taxasInput.media13x21Hipercard} onChange={handleInputTaxas}
                                               title=""
                                               required className="ip-tx" id='tax'/>
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>

                    {Array.from(mccArray).length > 0 ? <div className='mcc-list-h'>
                        <div className="mcc-l">
                            <p><b>Lista de todos MCC's disponíveis para esse CNPJ:</b></p>
                            {Array.from(mccArray).map((mcc) => {
                                return <div className="mcc-list">
                                    <p>{mcc.mcc} - {mcc.descricao_mcc_pt}</p>
                                </div>
                            })}</div>
                    </div> : ""}
                </div>}
            </div>
        </div>
    )
}

export default PrecificadorNovoLivre;
