import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import {useNavigate} from "react-router-dom";
import './Relatorio.css';
import {useEffect, useState} from "react";
import {Chart} from 'chart.js';
import LineChart from "../../components/LineChart";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {RevenueData, RevenueDailyData, TPVData, TPVDataDaily} from '../../resources/Data';

function Financeiro() {
    const [navVisible, showNavbar] = useState(false);
    const [selectedYear, setSelectedYear] = useState(2024);
    const [revenueDataYear, setRevenueDataYear] = useState([]);
    const [revenueAverageDataYear, setRevenueAverageDataYear] = useState([]);
    const navigate = useNavigate();
    const authenticatorRole = localStorage.getItem('role');
    const authenticatorDepartment = localStorage.getItem('department');
    let allMonths = [];
    for (let x in RevenueData) {
        if (RevenueData[x].id <= 12) allMonths.push(RevenueData[x].month);
    }

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value, 10));
    };

    const [chartData, setChartData] = useState({
        labels: allMonths,
        datasets: [{
            label: "Receita Total em milhares",
            data: revenueDataYear,
            borderColor: "rgb(15, 44, 40)",
            borderWidth: 2,
            tension: 0.15,
            fill: true,
            backgroundColor: 'rgba(207, 155, 80, 0.4)',
            plugins: [ChartDataLabels],
            datalabels: {
                color: 'black',
                align: ['end'],
                offset: 10,
                font: {
                    weight: 'regular',
                }
            },
        },
            {
                label: "Receita Média em milhares",
                data: revenueAverageDataYear,
                borderColor: "rgb(15, 44, 40)",
                borderWidth: 2,
                tension: 0.15,
                fill: true,
                backgroundColor: 'rgb(80, 207, 91, 0.75)',
                plugins: [ChartDataLabels],
                datalabels: {
                    color: 'black',
                    align: ['end'],
                    offset: 5,
                    font: {
                        weight: 'regular',
                    }
                },
            }
        ]
    });
    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        //maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return "R$  " + value + " K";
                    },
                },
            },
        },

    })
    Chart.register(ChartDataLabels);

    useEffect(() => {
        let tempRevenueDataYear = [];
        let tempRevenueAverageDataYear = [];

        for (let x in RevenueData) {
            if (RevenueData[x].year === selectedYear) {
                tempRevenueDataYear.push(RevenueData[x].revenue);
            }
        }

        for (let x in RevenueDailyData) {
            if (RevenueDailyData[x].year === selectedYear) {
                tempRevenueAverageDataYear.push(RevenueDailyData[x].average);
            }
        }

        setRevenueDataYear(tempRevenueDataYear);
        setRevenueAverageDataYear(tempRevenueAverageDataYear);
    }, [selectedYear]);

    useEffect(() => {
        setChartData({
            labels: allMonths,
            datasets: [{
                label: "Receita Total em milhares",
                data: revenueDataYear,
                borderColor: "rgb(15, 44, 40)",
                borderWidth: 2,
                tension: 0.15,
                fill: true,
                backgroundColor: 'rgba(207, 155, 80, 0.4)',
                plugins: [ChartDataLabels],
                datalabels: {
                    color: 'black',
                    align: ['end'],
                    offset: 10,
                    font: {
                        weight: 'regular',
                    }
                },
            },
                {
                    label: "Receita Média em milhares",
                    data: revenueAverageDataYear,
                    borderColor: "rgb(15, 44, 40)",
                    borderWidth: 2,
                    tension: 0.15,
                    fill: true,
                    backgroundColor: 'rgb(80, 207, 91, 0.75)',
                    plugins: [ChartDataLabels],
                    datalabels: {
                        color: 'black',
                        align: ['end'],
                        offset: 5,
                        font: {
                            weight: 'regular',
                        }
                    },
                }
            ]
        });
    }, [revenueDataYear, revenueAverageDataYear]);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => {
                        navigate('/relatorios')
                    }}>Voltar
                    </button>
                </div>
                <div>
                    <select value={selectedYear} onChange={handleYearChange} className= 'tpv-filter'>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                    </select>
                </div>
                <div className="chart">
                    <h3>Receita Mensal</h3>
                    <LineChart chartData={chartData} chartOptions={chartOptions}/>
                </div>
                <br></br>
                <h1 className='titulo_dash'>Painel Cobrança</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Painel de Cobrança" width="1140" height="541.25"
                            src="https://app.powerbi.com/view?r=eyJrIjoiMTcxMWFjYTItNzI4MC00MWNkLWI5YzQtZTg2YTc0MjY0ZTViIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9"
                            frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <br></br>
                <h1 className='titulo_dash'>Omie</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Omie" width="1140" height="541.25"
                            src="https://app.powerbi.com/view?r=eyJrIjoiMmEzYWM3NWItZDRmNS00ODMzLWExODQtNTM0NzkzOGYwZWQ2IiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9"
                            frameborder="0" allowFullScreen="true"></iframe>
                </div>
                {authenticatorRole === 'lider' || authenticatorDepartment === 'estrategiaeprodutos' ? <div>
                        <h1 className='titulo_dash'>Volumetria Geral Diretoria</h1>
                        <div className="visibleIframeContainer">
                            <iframe title="Volumetria Geral Diretoria" width="1140" height="541.25"
                                    src="https://app.powerbi.com/view?r=eyJrIjoiNTljZjljN2MtMDgwZi00YTUzLWI3NDItMTY3NTJlMzg0Y2VjIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9"
                                    frameBorder='0' allowFullScreen></iframe>
                        </div>
                    </div>
                    : <div></div>}
            </div>
        </div>
    )
}

export default Financeiro;
