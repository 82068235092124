import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Academia.css';

function ShortPDV() {
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton_geral" onClick={() => { navigate('/academia') }}>Voltar</button>
                </div>
                <h1>Shorts PDV</h1>
                <h3>Como adicionar um novo grupo - PDV</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/O1ImlvuHHdQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>Como adicionar produtos no PDV</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/KB76PYYtjZU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>Como adicionar um produto já cadastrado - PDV</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/hXScr26o910" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>Saiba tudo sobre os menus do Portal PDV</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/VF64x-gzKGc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>Como acessar os relatórios de vendas - PDV</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/FiZgh7ygOqk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
            </div>
        </div>
    )
}
export default ShortPDV;