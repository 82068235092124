import { useState, useEffect } from 'react';
import Axios from 'axios';
import BirthdayModel from '../../components/calendar/BirthdayModel';
import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import Calendar from 'react-calendar';

function CalendarPage() {

    var today = new Date();
    var todayDay = String(today.getDate()).padStart(2, '0');
    var todayMonth = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var todayYear = String(today.getFullYear());
    var todayFull = todayDay + '-' + todayMonth + '-' + todayYear;
    const [listUsersDt, setListUsersDt] = useState('');
    const [tgl, setTgl] = useState(new Date());
    const [navVisible, showNavbar] = useState(false);
    
    useEffect( () => {
         Axios.get(process.env.REACT_APP_CLIENT_BIRTHDAY).then((response) => {
            setListUsersDt(response.data);
        })
    }, []);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
            <div className='legenda'>
                        <p><b>Legenda:</b></p>
                        <p id='azul'>Azul - Dia atual</p>
                        <p id='dourado'>Dourado - Aniversários</p>
                    </div>
                <div id='calendarContainer' style={{ display: 'flex' }}>
                    <Calendar className='' onChange={setTgl} value={tgl}
                        tileClassName={({ date }) => {
                            let day = date.getDate();
                            let month = date.getMonth() + 1;
                            let year = date.getFullYear();

                            if ((date.getMonth() + 1) < 10) {
                                month = '0' + month;
                            }
                            if (date.getDate() < 10) {
                                day = '0' + day;
                            }
                            const formattedObject = Array.from(listUsersDt).map(({ name, ...rest }) => {
                                return rest;
                            })
                            const formattedDate = Array.from(formattedObject).map((date, index) => {
                                return date.dt_nasc.split('-').reverse().join('-').substr(0, 5);
                            })

                            const realDate = day + '-' + month;
                            const realDateFull = day + '-' + month + '-' + year;

                            if (realDateFull === todayFull) {
                                return 'today'
                            }

                            if (formattedDate.find(val => val === realDate)) {
                                return 'highlight'
                            }
                        }
                        }
                    />
                    <section className='dados_calendario'>
                        <h3 className='titulo_calendario'>Próximas datas</h3>
                        <div>
                            <h3 className='subtitulo_calendario'>Aniversários</h3>
                            {/* Sort para ordenar as datas em ordem crescente! */}
                            {Array.from(listUsersDt)
                                .sort((a, b) => (a.dt_nasc.split('-').reverse().join('-').substr(0, 2) > b.dt_nasc.split('-').reverse().join('-').substr(0, 2)) ? 1 : ((b.dt_nasc.split('-').reverse().join('-').substr(0, 2) > a.dt_nasc.split('-').reverse().join('-').substr(0, 2)) ? -1 : 0))
                                .sort((a, b) => (a.dt_nasc.split('-').reverse().join('-').substr(3, 2) > b.dt_nasc.split('-').reverse().join('-').substr(3, 2)) ? 1 : ((b.dt_nasc.split('-').reverse().join('-').substr(3, 2) > a.dt_nasc.split('-').reverse().join('-').substr(3, 2)) ? -1 : 0))
                                .map((user, index) => {
                                    if ((parseInt(user.dt_nasc.substr(8, 2)) >= parseInt(todayDay) && parseInt(user.dt_nasc.substr(5, 2)) === parseInt(todayMonth)) || parseInt(user.dt_nasc.substr(5, 2)) === parseInt(todayMonth) + 1 || parseInt(user.dt_nasc.substr(5, 2)) === parseInt(todayMonth) + 2) {
                                        return <BirthdayModel
                                            id={user.id}
                                            name={user.name}
                                            dt_nasc={user.dt_nasc}
                                            key={index}></BirthdayModel>
                                    }
                                })}
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default CalendarPage;