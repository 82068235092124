import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from '../pages/login/Login';
import Home from '../pages/home/Home';
import Register from '../pages/register/Register';
import Atendimento from '../pages/relatorios/Atendimento';
import Comercial from '../pages/relatorios/Comercial';
import Financeiro from '../pages/relatorios/Financeiro';
import Marketing from '../pages/relatorios/Marketing';
import Operacional from '../pages/relatorios/Operacional';
import RH from '../pages/relatorios/RH';
import Relatorio from '../pages/relatorios/Relatorio';
import Geral from '../pages/relatorios/Geral';
import Diretoria from '../pages/relatorios/Diretoria';
import {PrivateRoute} from '../resources/PrivatesRouters/PrivateRoute';
import {PrivateRouteAtendimento} from './PrivatesRouters/PrivateRouteAtendimento';
import {PrivateRouteComercial} from './PrivatesRouters/PrivateRouteComercial';
import {PrivateRouteDiretoria} from './PrivatesRouters/PrivateRouteDiretoria';
import {PrivateRouteEstrategia} from './PrivatesRouters/PrivateRouteEstrategia';
import {PrivateRouteFinanceiro} from './PrivatesRouters/PrivateRouteFinanceiro';
import {PrivateRouteMarketing} from './PrivatesRouters/PrivateRouteMarketing';
import {PrivateRouteOperacional} from './PrivatesRouters/PrivateRouteOperacional';
import {PrivateRouteRH} from './PrivatesRouters/PrivateRouteRH';
import {PrivateRouteNews} from './PrivatesRouters/PrivateRouteNews';
import {PrivateRoutePrecificador} from './PrivatesRouters/PrivateRoutePrecificador';
import News from '../pages/news/News';
import RegisterNews from '../pages/news/RegisterNews';
import CalendarPage from '../pages/calendar/CalendarPage';
import Profile from '../pages/profile/Profile';
import UpdateProfile from '../pages/profile/UpdateProfile';
import Faq from '../pages/faq/Faq';
import RegisterQuestion from '../pages/faq/RegisterQuestion';
import ConfirmEmail from '../pages/forgetPassword/ConfirmEmail';
import Analyzes from '../pages/analyzes/Analyzes';
import RegisterAnalysis from '../pages/analyzes/RegisterAnalysis';
import Academia from '../pages/academia/Academia';
import VideosAtendimento from '../pages/academia/VideosAtendimento';
import VideosEstrategiaProdutos from '../pages/academia/VideosEstrategiaProdutos';
import VideosFinanceiroFinanceiro from '../pages/academia/VideosFinanceiro';
import VideosOperacional from '../pages/academia/VideosOperacional';
import VideosComercial from '../pages/academia/VideosComercial';
import VideosRh from '../pages/academia/VideosRh';
import VideosMarketing from '../pages/academia/VideosMarketing';
import Orcamento from '../pages/finance/omie/Orcamento';
import ShortPDV from '../pages/academia/ShortsPDV';
import DeleteProfile from '../pages/profile/DeleteProfile';
import Finance from '../pages/finance/Finance';
import Omie from '../pages/finance/omie/Omie';
import Precificador from '../pages/precificador/Precificador';
import PrecificadorNovoAuto from '../pages/precificador/PrecificadorNovoAuto';
import PrecificadorNovoSemi from '../pages/precificador/PrecificadorNovoSemi';
import PrecificadorNovoLivre from '../pages/precificador/PrecificadorNovoLivre';
import BemEstar from '../pages/Forms/BemEstar';
import Logistica from '../pages/Forms/Logistica';
import Formularios from '../pages/Forms/Formularios';
import NotFound from '../components/notFoundPage/NotFound';
import ChartUpdate from "../pages/relatorios/ChartUpdate";
import CancelPayment from "../pages/cancelPayment/CancelPayment";

function MyRouter() {

    return (
        <BrowserRouter>
            <Routes>
                {/* Rotas não especificadas */}
                <Route path='*' element={<NotFound/>}/>

                {/* Rotas sem proteção */}
                <Route path='/login' element={<Login/>}/>
                <Route path='/confirmEmail' element={<ConfirmEmail/>}/>

                {/* Rotas com proteção básica */}
                <Route path='/calendar' element={<PrivateRoute><CalendarPage/></PrivateRoute>}/>
                <Route path='/bem-estar' element={<PrivateRoute><BemEstar/></PrivateRoute>}/>
                <Route path='/formularios' element={<PrivateRoute><Formularios/></PrivateRoute>}/>
                <Route path='/faq' element={<PrivateRoute><Faq/></PrivateRoute>}/>
                <Route path='/register/faq' element={<PrivateRoute><RegisterQuestion/></PrivateRoute>}/>
                <Route path='/' element={<PrivateRoute><Home/></PrivateRoute>}/>
                <Route path='/profile' element={<PrivateRoute><Profile/></PrivateRoute>}/>
                <Route path='/update/profile' element={<PrivateRoute><UpdateProfile/></PrivateRoute>}/>
                <Route path='/news' element={<PrivateRoute><News/></PrivateRoute>}/>
                <Route path='/relatorios' element={<PrivateRoute><Relatorio/></PrivateRoute>}/>
                <Route path='/relatorios/geral' element={<PrivateRoute><Geral/></PrivateRoute>}/>
                <Route path='/academia' element={<PrivateRoute><Academia/></PrivateRoute>}/>
                <Route path='/academia/shortspdv' element={<PrivateRoute><ShortPDV/></PrivateRoute>}/>

                {/* Rotas com proteção News (mkt, diretoria, eep, rh, finan)*/}
                <Route path='/register/news' element={<PrivateRouteNews><RegisterNews/></PrivateRouteNews>}/>

                {/* Rotas com proteção EstratégiaEProdutos */}
                <Route path='/cancel' element={<PrivateRouteEstrategia><CancelPayment/></PrivateRouteEstrategia>}/>
                <Route path='/relatorios/update'
                       element={<PrivateRouteEstrategia><ChartUpdate/></PrivateRouteEstrategia>}/>

                {/* Rotas com proteção precificador */}
                <Route path='/precificador'
                       element={<PrivateRoutePrecificador><Precificador/></PrivateRoutePrecificador>}/>
                <Route path='/precificador-auto'
                       element={<PrivateRoutePrecificador><PrecificadorNovoAuto/></PrivateRoutePrecificador>}/>
                <Route path='/precificador-semi'
                       element={<PrivateRoutePrecificador><PrecificadorNovoSemi/></PrivateRoutePrecificador>}/>
                <Route path='/precificador-livre'
                       element={<PrivateRoutePrecificador><PrecificadorNovoLivre/></PrivateRoutePrecificador>}/>

                {/* Rotas com proteção Atendimento */}
                <Route path='/relatorios/atendimento'
                       element={<PrivateRouteAtendimento><Atendimento/></PrivateRouteAtendimento>}/>
                <Route path='/academia/videosAtendimento'
                       element={<PrivateRouteAtendimento><VideosAtendimento/></PrivateRouteAtendimento>}/>


                {/* Rotas com proteção Comercial */}
                <Route path='/relatorios/comercial'
                       element={<PrivateRouteComercial><Comercial/></PrivateRouteComercial>}/>
                <Route path='/academia/videosComercial'
                       element={<PrivateRouteComercial><VideosComercial/></PrivateRouteComercial>}/>


                {/* Rotas com proteção Diretoria */}
                <Route path='/relatorios/diretoria'
                       element={<PrivateRouteDiretoria><Diretoria/></PrivateRouteDiretoria>}/>
                <Route path='/analyzes' element={<PrivateRouteDiretoria><Analyzes/></PrivateRouteDiretoria>}/>
                <Route path='/register/analysis'
                       element={<PrivateRouteDiretoria><RegisterAnalysis/></PrivateRouteDiretoria>}/>
                <Route path='/academia/videosEstrategiaProdutos'
                       element={<PrivateRouteDiretoria><VideosEstrategiaProdutos/></PrivateRouteDiretoria>}/>
                <Route path='/academia/videosMarketing'
                       element={<PrivateRouteDiretoria><VideosMarketing/></PrivateRouteDiretoria>}/>


                {/* Rotas com proteção Financeiro */}
                <Route path='/register/user' element={<PrivateRouteFinanceiro><Register/></PrivateRouteFinanceiro>}/>
                <Route path='/delete/profile'
                       element={<PrivateRouteFinanceiro><DeleteProfile/></PrivateRouteFinanceiro>}/>
                <Route path='/financeiro' element={<PrivateRouteFinanceiro><Finance/></PrivateRouteFinanceiro>}/>
                <Route path='/financeiro/omie' element={<PrivateRouteFinanceiro><Omie/></PrivateRouteFinanceiro>}/>
                <Route path='/relatorios/financeiro'
                       element={<PrivateRouteFinanceiro><Financeiro/></PrivateRouteFinanceiro>}/>
                <Route path='/academia/videosFinanceiro'
                       element={<PrivateRouteFinanceiro><VideosFinanceiroFinanceiro/></PrivateRouteFinanceiro>}/>
                <Route path='/financeiro/omie/orcamento'
                       element={<PrivateRouteFinanceiro><Orcamento/></PrivateRouteFinanceiro>}/>

                {/* Rotas com proteção Marketing */}
                <Route path='/relatorios/marketing'
                       element={<PrivateRouteMarketing><Marketing/></PrivateRouteMarketing>}/>

                {/* Rotas com proteção Operacional */}
                <Route path='/relatorios/operacional'
                       element={<PrivateRouteOperacional><Operacional/></PrivateRouteOperacional>}/>
                <Route path='/academia/videosOperacional'
                       element={<PrivateRouteOperacional><VideosOperacional/></PrivateRouteOperacional>}/>
                <Route path='/logistica' element={<PrivateRouteOperacional><Logistica/></PrivateRouteOperacional>}/>

                {/* Rotas com proteção RH */}
                <Route path='/relatorios/rh' element={<PrivateRouteRH><RH/></PrivateRouteRH>}/>
                <Route path='/academia/videosRh' element={<PrivateRouteRH><VideosRh/></PrivateRouteRH>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default MyRouter;
