import React from "react";
import ReactSpeedometer from "react-d3-speedometer";

const styles = {
  dial: {
    display: "inline-block",
    width: `300px`,
    height: `auto`,
    color: "#000",
    border: "0.5px solid #fff",
    padding: "2px",
    fontSize: "12px"
  },
  title: {
    display: "flex",
    fontSize: "14px",
    color: "black",
    fontfamily: "Segoe UI"
  }
};

const Speedometer = ({ maxValue, minValue, avgValue }) => {

  return (
    <div style={styles.dial}>
      <ReactSpeedometer
        maxValue={maxValue}
        minValue={minValue}
        height={190}
        width={290}
        value={avgValue}
        needleTransition="easeQuadIn"
        needleTransitionDuration={1000}
        needleColor="black"
        startColor="salmon"
        segments={2}
        labelFontSize={12}
        customSegmentStops={[minValue, avgValue, maxValue]}
        endColor="lime"
        forceRender="true"
        valueFormat=".2%"
      />
      <p>*Relógio referente aos custos e não as taxas finais</p>
    </div>
  );
};

export default Speedometer;
