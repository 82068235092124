import { useState } from "react";
import './News.css';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';

function RegisterNews() {

    var msg = null;
    const [navVisible, showNavbar] = useState(false);
    const [news, setNews] = useState({
        title: "",
        content: "",
        dt_creation: "",
        author: ""
    });
    const preventSubmit = (e) => {
        e.preventDefault();
    };
    const handleInput = (e) => {
        setNews({ ...news, [e.target.name]: e.target.value });
    };
    const navigate = useNavigate();

    const handleSubmit = async () => {
        news.author = localStorage.getItem('userName');

        // envia a requisição para cadastrar a notícia
        await Axios.post(process.env.REACT_APP_CLIENT_REGISTER_NEWS, {
            title: news.title,
            content: news.content,
            dt_creation: news.dt_creation,
            author: news.author
        }).then((response) => {
            msg = response.data.msg;
            if (msg === 'newsAlreadyRegistered') alert('Notícia já está registrada.');
            if (msg === 'newNewsAdded') {
                alert('Nova notícia cadastrada.');
                navigate('/news');
            }
        })
    };

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                
                {/* Formulário para criar a notícia */}
                <div className="container_news">
                    <h2>Criar nova notícia:</h2>
                    <form method='news' onSubmit={preventSubmit}>
                        <p>Nome:</p>
                        <input type="text" name="title" placeholder="Título" value={news.title} onChange={handleInput} required />
                        <p>Conteúdo:</p>
                        <textarea required rows="15" cols="40" name="content" value={news.content} onChange={handleInput}>Escreva o news aqui</textarea>
                        <p>Data:</p>
                        <input type="date" name="dt_creation" placeholder="Data" value={news.dt_creation} onChange={handleInput} required />
                        <br /><br />
                        <button className="news_btn" onClick={handleSubmit}>Cadastrar</button>
                        <button className="news_btn_back" onClick={(e) => { navigate('/news') }}>Voltar</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RegisterNews;