import {useEffect, useState} from "react";
import Axios from 'axios';
import Loading from "../../components/Loading";
import './Precificador.css';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import PrecificadorPdf from "../../components/precificador/PrecificadorPdf";
import {BiInfoCircle, BiSearch} from 'react-icons/bi';
import {BsDownload} from 'react-icons/bs';
import html2pdf from 'html2pdf.js';
import {useNavigate} from "react-router-dom";
import Speedometer from "../../components/Speedometer";
import {alertaAlcada, alertaCampoObrigatorioSemi, clockFormater} from "./utils";

function PrecificadorNovoSemi() {
    const navigate = useNavigate();
    const [consulta, setConsulta] = useState({
        cnpj: '',
        mcc: '',
        cidade: '',
        bairro: '',
        tpv: 50000,
        spreadDesejado: '0.8',
        taxaAntecipacao: '1.50',
        percentualDebito: 20,
        percentualAVista: 20,
        percentual2x6: 20,
        percentual7x12: 20,
        percentual13x21: 20,
        noCusto: false,
        eCommerce: false
    });
    const [percentualTotal, setPercentualTotal] = useState("0");
    const [isLoading, setIsLoading] = useState(false);
    const [navVisible, showNavbar] = useState(false);
    const [razaoSocial, setRazaoSocial] = useState('');
    const [spread, setSpread] = useState('');
    const [taxaAntecipacaoPdf, setTaxaAntecipacaoPdf] = useState('');
    const [mccPDF, setMccPdf] = useState('');
    const [propriedade, setPropriedade] = useState('mediaDebitoMaster');
    const [bandeira, setBandeira] = useState('Master');
    const [tipoPagamento, setTipoPagamento] = useState('Debito');
    const [taxasOriginal, setTaxasOriginal] = useState([]);
    const [taxasFinal, setTaxasFinal] = useState([]);
    const [taxasMaximas, setTaxasMaximas] = useState([]);
    const [taxasMinimas, setTaxasMininas] = useState([]);
    const [mccArray, setMccArray] = useState([]);
    const [selectOptionCidade, setSelectOptionCidade] = useState([]);
    const [selectOptionBairro, setSelectOptionBairro] = useState([]);
    const [check13x21, setCheck13x21] = useState(true);

    const userDepartment = localStorage.getItem('department');

    const handleInput = (e) => {
        setConsulta({...consulta, [e.target.name]: e.target.value})
    };

    const handle13x21 = (e) => {
        const consultaTemp = {...consulta};
        if (check13x21) {
            setCheck13x21(false);
            consultaTemp.percentualDebito = 25;
            consultaTemp.percentualAVista = 25;
            consultaTemp.percentual2x6 = 25;
            consultaTemp.percentual7x12 = 25;
            consultaTemp.percentual13x21 = 0;
            setConsulta(consultaTemp);
        } else if (!check13x21) {
            setCheck13x21(true)
            consultaTemp.percentualDebito = 20;
            consultaTemp.percentualAVista = 20;
            consultaTemp.percentual2x6 = 20;
            consultaTemp.percentual7x12 = 20;
            consultaTemp.percentual13x21 = 20;
            setConsulta(consultaTemp);
        }
        setCheck13x21(e.target.checked);
    }

    const handleNoCusto = (e) => {
        if (e.target.checked) setConsulta({...consulta, noCusto: true});
        else if (!e.target.checked) setConsulta({...consulta, noCusto: false})
    };

    const handleECommerce = (e) => {
        if (e.target.checked) setConsulta({...consulta, eCommerce: true});
        else if (!e.target.checked) setConsulta({...consulta, eCommerce: false});
    }

    const inputBandeira = (e) => {
        let valor = e.target.value;
        setBandeira(valor);
    };

    const inputTipoPagamento = (e) => {
        let valor = e.target.value;
        setTipoPagamento(valor);
    };

    const consultarCNPJ = async () => {
        setIsLoading(true);
        await Axios.post(process.env.REACT_APP_CLIENT_GET_CNAE, {
            cnpj: consulta.cnpj,
        }).then(async (response) => {
            if (response.data.msg === 'CNPJ invalido') {
                alert('CNPJ inválido!');
                setIsLoading(false);
                return;
            }

            let empresa = response.data;
            setRazaoSocial(empresa.razaoSocial);

            // Transformar em '0' se a taxa de antecipação for uma string vazia
            if (consulta.taxaAntecipacao === '') {
                var tempConsulta = {...consulta};
                tempConsulta.taxaAntecipacao = '0';
                setConsulta(tempConsulta);
            }

            await Axios.post(process.env.REACT_APP_CLIENT_GET_MCC, {
                cnaes: empresa.cnaes
            }).then((response) => {
                let mccArray = response.data;
                setMccArray(mccArray);
                // Resetar taxas após busca por novo CPNJ
                setTaxasFinal([]);
                setTaxasOriginal([]);
            })
            setIsLoading(false);
        });
    };

    const gerarTaxas = async () => {
        setIsLoading(true);
        try {
            const response = await Axios.post(process.env.REACT_APP_CLIENT_GET_TAXES_SEMI, {
                mcc: consulta.mcc,
                cidade: consulta.cidade,
                bairro: consulta.bairro,
                spreadDesejado: consulta.spreadDesejado,
                taxaAntecipacao: consulta.taxaAntecipacao,
                percentualDebito: consulta.percentualDebito,
                percentualAVista: consulta.percentualAVista,
                percentual2x6: consulta.percentual2x6,
                percentual7x12: consulta.percentual7x12,
                percentual13x21: consulta.percentual13x21,
                check13x21: check13x21,
                noCusto: consulta.noCusto,
                eCommerce: consulta.eCommerce
            });

            setSpread(response.data[0].spread);
            setTaxasOriginal(response.data[1].taxasBase);
            setTaxasFinal(response.data[2].taxasFinal);
            setTaxasMaximas(response.data[3].taxasMaximas);
            setTaxasMininas(response.data[4].taxasMinimas);
            setTaxaAntecipacaoPdf(consulta.taxaAntecipacao);
            setMccPdf(consulta.mcc);

        } catch (error) {
            console.log(error + '\nErro ao gerar a taxa: ' + error.response.data.msg);
            setSpread('');
            setTaxasOriginal([]);
            setTaxasFinal([]);
            setTaxasMaximas([]);
            setTaxasMininas([]);
            setTaxaAntecipacaoPdf('');

            alert('Erro ao gerar a taxa. ' + error.response.data.msg);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        setSpread('');
        setTaxasOriginal([]);
        setTaxasFinal([]);
        setTaxasMaximas([]);
        setTaxasMininas([]);
        setTaxaAntecipacaoPdf('');
    }, [check13x21, consulta.noCusto, consulta.eCommerce]);

    const igualarVisaMaster = () => {
        let newTaxas = {...taxasFinal};

        if (taxasFinal.mediaDebitoVisa < taxasFinal.mediaDebitoMaster) {
            newTaxas.mediaDebitoVisa = newTaxas.mediaDebitoMaster;
        } else if (taxasFinal.mediaDebitoVisa >= taxasFinal.mediaDebitoMaster) {
            newTaxas.mediaDebitoMaster = newTaxas.mediaDebitoVisa;
        }

        if (taxasFinal.mediaAVistaVisa < taxasFinal.mediaAVistaMaster) {
            newTaxas.mediaAVistaVisa = newTaxas.mediaAVistaMaster;
        } else if (taxasFinal.mediaAVistaVisa >= taxasFinal.mediaAVistaMaster) {
            newTaxas.mediaAVistaMaster = newTaxas.mediaAVistaVisa;
        }

        if (taxasFinal.media2x6Visa < taxasFinal.media2x6Master) {
            newTaxas.media2x6Visa = newTaxas.media2x6Master;
        } else if (taxasFinal.media2x6Visa >= taxasFinal.media2x6Master) {
            newTaxas.media2x6Master = newTaxas.media2x6Visa;
        }

        if (taxasFinal.media7x12Visa < taxasFinal.media7x12Master) {
            newTaxas.media7x12Visa = newTaxas.media7x12Master;
        } else if (taxasFinal.media7x12Visa >= taxasFinal.media7x12Master) {
            newTaxas.media7x12Master = newTaxas.media7x12Visa;
        }

        if (taxasFinal.media13x21Visa < taxasFinal.media13x21Master) {
            newTaxas.media13x21Visa = newTaxas.media13x21Master;
        } else if (taxasFinal.media13x21Visa >= taxasFinal.media13x21Master) {
            newTaxas.media13x21Master = newTaxas.media13x21Visa;
        }

        setTaxasFinal(newTaxas);

        Axios.post(process.env.REACT_APP_CLIENT_GET_NEW_SPREAD, {
            taxasOriginal: taxasOriginal,
            taxasFinal: newTaxas,
            taxaAntecipacao: consulta.taxaAntecipacao,
            percentualDebito: consulta.percentualDebito,
            percentualAVista: consulta.percentualAVista,
            percentual2x6: consulta.percentual2x6,
            percentual7x12: consulta.percentual7x12,
            percentual13x21: consulta.percentual13x21,
            check13x21: check13x21,
            noCusto: consulta.noCusto,
            eCommerce: consulta.eCommerce
        }).then((response) => {
            setSpread(response.data[0]);
        });
    };

    const igualarAmexHiper = () => {
        let newTaxas = {...taxasFinal};

        if (taxasFinal.mediaDebitoAmex < taxasFinal.mediaDebitoHipercard) {
            newTaxas.mediaDebitoAmex = newTaxas.mediaDebitoHipercard;
        } else if (taxasFinal.mediaDebitoAmex >= taxasFinal.mediaDebitoHipercard) {
            newTaxas.mediaDebitoHipercard = newTaxas.mediaDebitoAmex;
        }

        if (taxasFinal.mediaAVistaAmex < taxasFinal.mediaAVistaHipercard) {
            newTaxas.mediaAVistaAmex = newTaxas.mediaAVistaHipercard;
        } else if (taxasFinal.mediaAVistaAmex >= taxasFinal.mediaAVistaHipercard) {
            newTaxas.mediaAVistaHipercard = newTaxas.mediaAVistaAmex;
        }

        if (taxasFinal.media2x6Amex < taxasFinal.media2x6Hipercard) {
            newTaxas.media2x6Amex = newTaxas.media2x6Hipercard;
        } else if (taxasFinal.media2x6Amex >= taxasFinal.media2x6Hipercard) {
            newTaxas.media2x6Hipercard = newTaxas.media2x6Amex;
        }

        if (taxasFinal.media7x12Amex < taxasFinal.media7x12Hipercard) {
            newTaxas.media7x12Amex = newTaxas.media7x12Hipercard;
        } else if (taxasFinal.media7x12Amex >= taxasFinal.media7x12Hipercard) {
            newTaxas.media7x12Hipercard = newTaxas.media7x12Amex;
        }

        if (taxasFinal.media13x21Amex < taxasFinal.media13x21Hipercard) {
            newTaxas.media13x21Amex = newTaxas.media13x21Hipercard;
        } else if (taxasFinal.media13x21Amex >= taxasFinal.media13x21Hipercard) {
            newTaxas.media13x21Hipercard = newTaxas.media13x21Amex;
        }

        setTaxasFinal(newTaxas);

        Axios.post(process.env.REACT_APP_CLIENT_GET_NEW_SPREAD, {
            taxasOriginal: taxasOriginal,
            taxasFinal: newTaxas,
            taxaAntecipacao: consulta.taxaAntecipacao,
            percentualDebito: consulta.percentualDebito,
            percentualAVista: consulta.percentualAVista,
            percentual2x6: consulta.percentual2x6,
            percentual7x12: consulta.percentual7x12,
            percentual13x21: consulta.percentual13x21,
            check13x21: check13x21,
            noCusto: consulta.noCusto,
            eCommerce: consulta.eCommerce
        }).then((response) => {
            setSpread(response.data[0]);
        });
    };

    useEffect(() => {
        setIsLoading(true);
        Axios.get(process.env.REACT_APP_CLIENT_GET_CIDADE).then((response) => {
            setSelectOptionCidade(response.data);
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        setIsLoading(true);
        Axios.post(process.env.REACT_APP_CLIENT_GET_BAIRRO, {
            cidade: consulta.cidade
        }).then((response) => {
            setSelectOptionBairro(response.data);
            setIsLoading(false);
        })
    }, [consulta.cidade]);

    useEffect(() => {
        let percentualTotal =
            parseInt(consulta.percentualDebito)
            + parseInt(consulta.percentualAVista)
            + parseInt(consulta.percentual2x6)
            + parseInt(consulta.percentual7x12)
            + parseInt(consulta.percentual13x21);
        if (isNaN(percentualTotal)) setPercentualTotal(0);
        else setPercentualTotal(percentualTotal);
    }, [
        consulta.percentualDebito,
        consulta.percentualAVista,
        consulta.percentual2x6,
        consulta.percentual7x12,
        consulta.percentual13x21]);

    useEffect(() => {
        let prop = "media" + tipoPagamento + bandeira;
        setPropriedade(prop);
    }, [tipoPagamento, bandeira]);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "pag" : "pag pag-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => {
                        navigate('/precificador')
                    }}>Voltar
                    </button>
                </div>
                <div className="data-precificador-perc">
                    <table className="tb-prec">
                        <tr>
                            <td>CNPJ</td>
                            <td>MCC</td>
                            <td>Cidade</td>
                            <td>Bairro</td>
                            <td>TPV</td>
                            <td>Spread</td>
                            <td>Antecipação</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" name="cnpj" value={consulta.cnpj} onChange={handleInput} title="CNPJ"
                                       required id="cnpj"/>
                            </td>
                            <td>
                                <input type="text" name="mcc" value={consulta.mcc} onChange={handleInput} title="MCC"
                                       id="mcc" required maxLength='4' size="4"/>
                            </td>
                            <td>
                                <select name="cidade" onChange={handleInput} className="ct">
                                    <option value=""></option>
                                    {Array.from(selectOptionCidade).map((option, index) => {
                                        return <option value={option.CIDADE} key={index}>{option.CIDADE}</option>
                                    })}
                                </select>
                            </td>
                            <td>
                                <select name="bairro" onChange={handleInput} className="ngbh">
                                    <option value="" selected disabled hidden></option>
                                    {Array.from(selectOptionBairro).map((option, index) => {
                                        return <option value={option.BAIRRO} key={index}>{option.BAIRRO}</option>
                                    })}
                                </select>
                            </td>

                            <td>
                                <input type="text" name="tpv" value={consulta.tpv} onChange={handleInput} title="TPV"
                                       id="tpv" required/>
                            </td>
                            <td>
                                <input type="text" name="spreadDesejado" value={consulta.spreadDesejado}
                                       onChange={handleInput} title="SPREAD" id="spread" required/>
                            </td>
                            <td>
                                <input type="text" name="taxaAntecipacao" value={consulta.taxaAntecipacao}
                                       onChange={handleInput} title="TAXA DE ANTECIPAÇÃO"/>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="data-precificador-perc">
                    <table className="tb-prec">
                        <tr>
                            <td>% Débito</td>
                            <td>% À vista</td>
                            <td>% 2x - 6x</td>
                            <td>% 7x - 12x</td>
                            {check13x21 && (
                                <td>% 13x - 21x</td>
                            )}
                            <td>13x - 21x</td>
                            {(userDepartment === 'diretoria' || userDepartment === 'estrategiaeprodutos') && (
                                <td>Calcular no custo</td>
                            )}
                            <td>E-Commerce</td>
                        </tr>
                        <tr>
                            <td>
                                <input type="text" name="percentualDebito" placeholder="% Débito"
                                       value={consulta.percentualDebito} onChange={handleInput} title="% DÉBITO"
                                       required/>
                            </td>
                            <td>
                                <input type="text" name="percentualAVista" placeholder="% A vista"
                                       value={consulta.percentualAVista} onChange={handleInput} title="% A VISTA"
                                       required/>
                            </td>
                            <td>
                                <input type="text" name="percentual2x6" placeholder="% 2 x 6 vezes"
                                       value={consulta.percentual2x6} onChange={handleInput} title="% 2 X 6 VEZES"
                                       required/>
                            </td>
                            <td>
                                <input type="text" name="percentual7x12" placeholder="% 7 x 12 vezes"
                                       value={consulta.percentual7x12} onChange={handleInput} title="% 7 X 12 VEZES"
                                       required/>
                            </td>
                            {check13x21 && (
                                <td>
                                    <input type="text" name="percentual13x21" placeholder="% 13 x 21 vezes"
                                           value={consulta.percentual13x21} onChange={handleInput}
                                           title="% 13 X 21 VEZES"
                                           required/>
                                </td>
                            )}
                            <td className={"13x21"}>
                                <input
                                    type="checkbox"
                                    name="checkbox13x21" placeholder="% 13 x 21 vezes"
                                    checked={check13x21} onChange={handle13x21} title="13x21"
                                />
                            </td>
                            {(userDepartment === 'diretoria' || userDepartment === 'estrategiaeprodutos') && (
                                <td>
                                    <input type="checkbox"
                                           name="noCusto" checked={consulta.noCusto} onChange={handleNoCusto}/>
                                </td>
                            )}
                            <td>
                                <input
                                    type="checkbox"
                                    name="eCommerce"
                                    checked={consulta.eCommerce}
                                    onChange={handleECommerce}
                                />
                            </td>
                            <td>
                                <p className={percentualTotal < 100 || percentualTotal > 100 ? "tt-p-red" : "tt-p"}>Percentual
                                    total: {percentualTotal}%</p>
                            </td>
                        </tr>
                    </table>
                </div>
                <p className="subt">* Taxas com spreads baixos serão enviadas aos líderes dos setores para aprovação</p>
                <p className="subt">* Caso esteja usando o celular, use o modo horizontal para gerar o PDF </p>
                <div className="btn-prec">
                    <button onClick={consultarCNPJ}>Consultar CNPJ<BiSearch/></button>
                    <button onClick={() => alertaCampoObrigatorioSemi(consulta, gerarTaxas)}>Gerar Taxas <BiSearch/></button>
                    <button onClick={() => {alertaAlcada(consulta, razaoSocial, spread)}} disabled={taxasFinal.length === 0 || isLoading ? true : false}>Baixar
                        PDF <BsDownload/></button>
                    <button onClick={igualarVisaMaster}
                            disabled={taxasFinal.length === 0 || isLoading ? true : false}>Igualar Visa e Master
                    </button>
                    <button onClick={igualarAmexHiper}
                            disabled={taxasFinal.length === 0 || isLoading ? true : false}>Igualar Amex e Hipercard
                    </button>
                    <a href="https://www.monitorabecs.com.br/mcc/busca/index.aspx" target="__blank">Consultar o CNPJ na
                        ABECS</a><BiInfoCircle size={30} color="rgb(117, 116, 116)"
                                               title="Para garantir que o MCC é válido, verifique se o mesmo é aceito pela ABECS"/>
                </div>

                {isLoading ? <Loading/> : <div>
                    {taxasFinal.length !== 0 ? <div>
                        <div className="cinfo">
                            <p>
                                <b> MCC utilizado: </b>{mccPDF} <br/>
                                <b> Spread: </b>{(spread * 100).toPrecision(3).toString().replace(".", ",")}%
                            </p>
                        </div>

                        <PrecificadorPdf
                            taxaAntecipacao={taxaAntecipacaoPdf}
                            taxasFinal={taxasFinal}
                            razaoSocial={razaoSocial}
                            check13x21={check13x21}
                        />
                        <table className="tb-prec">
                            <tr>
                                <td>Bandeira</td>
                                <td>Tipo Pagamento</td>
                            </tr>
                            <tr>
                                <td><select onChange={inputBandeira}>
                                    <option name="Mastercard" value="Master">Mastercard</option>
                                    <option name="Visa" value="Visa">Visa</option>
                                    <option name="Elo" value="Elo">Elo</option>
                                </select></td>
                                <td><select onChange={inputTipoPagamento}>

                                    <option name="Débito" value="Debito">Débito</option>
                                    <option name="A vista" value="AVista">A vista</option>
                                    <option name="2x" value="2x6-2x">2x</option>
                                    <option name="3x" value="2x6-3x">3x</option>
                                    <option name="4x" value="2x6-4x">4x</option>
                                    <option name="5x" value="2x6-5x">5x</option>
                                    <option name="6x" value="2x6-6x">6x</option>
                                    <option name="7x" value="7x12-7x">7x</option>
                                    <option name="8x" value="7x12-8x">8x</option>
                                    <option name="9x" value="7x12-9x">9x</option>
                                    <option name="10x" value="7x12-10x">10x</option>
                                    <option name="11x" value="7x12-11x">11x</option>
                                    <option name="12x" value="7x12-12x">12x</option>
                                    <option name="13x" value="13x21-13x">13x</option>
                                    <option name="14x" value="13x21-14x">14x</option>
                                    <option name="15x" value="13x21-15x">15x</option>
                                    <option name="16x" value="13x21-16x">16x</option>
                                    <option name="17x" value="13x21-17x">17x</option>
                                    <option name="18x" value="13x21-18x">18x</option>
                                    <option name="19x" value="13x21-19x">19x</option>
                                    <option name="20x" value="13x21-20x">20x</option>
                                    <option name="21x" value="13x21-21x">21x</option>
                                </select></td>
                            </tr>
                        </table>

                        <div className="relogio">
                            <Speedometer
                                maxValue={clockFormater(taxasMaximas, propriedade, taxaAntecipacaoPdf)}
                                minValue={clockFormater(taxasMinimas, propriedade, taxaAntecipacaoPdf)}
                                avgValue={clockFormater(taxasOriginal, propriedade, taxaAntecipacaoPdf)}
                            />
                        </div>

                    </div> : <div></div>}
                    {Array.from(mccArray).length > 0 ? <div className='mcc-list-h'>
                        <div className="mcc-l">
                            <p><b>Lista de todos MCC's disponíveis para esse CNPJ:</b></p>
                            {Array.from(mccArray).map((mcc) => {
                                return <div className="mcc-list">
                                    <p>{mcc.mcc} - {mcc.descricao_mcc_pt} </p>
                                </div>
                            })}</div>
                    </div> : ""}
                </div>}
            </div>
        </div>
    )
}

export default PrecificadorNovoSemi;

