import {useState, useEffect} from 'react';
import Axios from 'axios';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import {useNavigate} from 'react-router-dom';
import PdfViewerModel from './PdfViewerModel';
import Loading from '../../components/Loading';
import './Analyzes.css';

function Analyzes() {
    const [listAnalyzes, setListAnalyzes] = useState('');
    const [navVisible, showNavbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        Axios.get(process.env.REACT_APP_CLIENT_ANALYZES).then((response) => {
            setListAnalyzes(response.data);
        });
        setIsLoading(false);
    }, []);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={navVisible ? "page page-with-navbar" : "page"}>

                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => {
                        navigate('/relatorios/diretoria')
                    }}>Voltar
                    </button>
                </div>
                <button className="analyzesButton" onClick={() => {
                    navigate('/register/analysis')
                }}>Registar análise
                </button>
                {isLoading ? <Loading/> : <div className='analyzesContainer'>
                    {Array.from(listAnalyzes)
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((analysis, index) => {
                            return <PdfViewerModel
                                id={index}
                                title={analysis.title}
                                description={analysis.description}
                                link={analysis.pdfLink}
                                date={analysis.date}
                                author={analysis.author}/>
                        })}
                </div>}
            </div>
        </div>
    )
}

export default Analyzes;
