import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Relatorio.css';

function Geral() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    
    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton_geral" onClick={() => { navigate('/relatorios') }}>Voltar</button>
                </div>
                <br />
                <h1 className="titulo_dash">Buscador de TPV</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Buscador de TPV" width="1140" height="541.25" src="https://app.fabric.microsoft.com/view?r=eyJrIjoiMTU2ZjJkNzMtMDBmMy00MDI1LWJhMzAtYzc2NjY5YzQ3ZGQ5IiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <br></br>
                <h1 className='titulo_dash'>Buscador de Indicações</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Buscador de indicações" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiNmVjOTAzNTktOWZkNi00ZDk0LWI5ZTktMDk1YWEyZWNiYmM4IiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <br></br>
                <h1 className='titulo_dash'>PPV</h1>
                <div className="visibleIframeContainer">
                    <iframe title="ppv" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiMDFkNjE3YjktYzMxZC00ZjRkLWIwMmItOWM4NmVkM2E0YzBlIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <br />
            </div>
        </div>
    )
}

export default Geral;