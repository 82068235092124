import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Academia.css';


function Academia() {
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
            
                <div className="department-list">
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosatendimento') }}>Atendimento</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosComercial') }}>Comercial</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosestrategiaprodutos') }}>Estratégia e Produtos</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosfinanceiro') }}>Financeiro</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosOperacional') }}>Operacional</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosRh') }}>RH</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/videosMarketing') }}>Marketing</button>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => { navigate('/academia/shortsPDV') }}>Shorts PDV</button>
                </div>
                <h1>O que é a V. Academy?</h1>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/LRNKRmuAP30" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
            </div>
        </div>
    )
}
export default Academia;