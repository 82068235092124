import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Relatorio.css';

function Comercial() {

    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    
    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/relatorios') }}>Voltar</button>
                </div>
                <br />
                <h1 className='titulo_dash'>Relatório de Vendas</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Relatório de Vendas" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiMmYzZjI4ODAtNTM0Yi00ODA0LWEwZTYtZGEzZDNhNjhmMzMzIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9 " frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <h1 className='titulo_dash'>PPV Assistente Comercial</h1>
                <div className="visibleIframeContainer">
                    <iframe title="PPV_Assitente_Comercial" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiMDUwODE5MGYtZmQ1NC00Nzc3LTk0OTItMzQ4OTA1MmVkYjZiIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Comercial;