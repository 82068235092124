import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Relatorio.css';

function Operacional() {
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/relatorios') }}>Voltar</button>
                </div>
                <br />
                <h1 className='titulo_dash'>PPV Operacional</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Relatório PPV - Operacional" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiNDJmYWRhMmEtN2M2NC00YWYzLWI5ZTYtZjAzNDY5ZjJhNjlkIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <h1 className='titulo_dash'>Relatório Logística</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Relatório Logística" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiODg2NThiZWEtNDE4OS00YzM3LThjMjktYTY4MjQyY2FmY2UzIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <h1 className='titulo_dash'>Relatório Estoque SUB</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Relatório Estoque SUB" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiMWVjYjFiYjMtNjQ4Zi00YzM5LWExOWItMjQ3Mjg0NGYxZjk3IiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Operacional;