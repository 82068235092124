import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Relatorio.css';

function RH() {
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/relatorios') }}>Voltar</button>
                </div>
                <h1 className="titulo_dash">Relatório de Humor</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Relatório de Humor" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiODgwOTg0NzAtMWE3OS00NGIwLTk3MzEtMTBiMTk5OGM5ODI2IiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
                <h1 className="titulo_dash">Controle de Promoções</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Controle de Promocoes" width="1140" height="541.25" src="https://app.powerbi.com/view?r=eyJrIjoiMmRjN2JjMmItMmY2Yy00MzNlLWIzYjAtMjhkODNkZTI4ZmMzIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9" frameborder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>
    )
}

export default RH;