import { Navigate } from "react-router-dom";
import departmentAuthentication from "../authentication/departmentAuthentication";
import tokenAuthentication from "../authentication/tokenAuthentication";

export const PrivateRoutePrecificador = ({ children }) => {

    const authenticator = localStorage.getItem('department');
    const roleAuthenticator = localStorage.getItem('role');

    if (departmentAuthentication() && tokenAuthentication()) {
        if (authenticator === 'estrategiaeprodutos' || authenticator === 'diretoria' || authenticator === 'operacional' || authenticator === 'atendimento' || roleAuthenticator === 'lider') {
            return children;
        }
    };

    alert('Você não tem permissão para acessar essa área!');
    return <Navigate to="/" />
}

