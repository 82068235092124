import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Finance.css';


function Finance() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    
    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="options">
                    <div className="">
                        <button  className='option-btn' onClick={() => { navigate('/financeiro/omie') }}>Integrações Omie</button>
                    </div>
                    <div className="">
                        <button className='option-btn' onClick={() => { navigate('/relatorios/financeiro') }}>Relatórios</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Finance;