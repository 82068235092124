import html2pdf from "html2pdf.js";
import Axios from "axios";

export function clockFormater(taxa, propriedade, taxaAntecipacao) {
    if (propriedade.startsWith('mediaDebito')) {
        if (taxa.hasOwnProperty(propriedade)) {
            return taxa[propriedade];
        }
    } else if (propriedade.startsWith('mediaAVista')) {
        if (taxa.hasOwnProperty(propriedade)) {
            return taxa[propriedade] + taxaAntecipacao / 100 * 1;
        }
    } else if (propriedade.startsWith('media2x6')) {
        if (propriedade.substr(0, 11).endsWith('2x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 8) + propriedade.substr(11, 30))) {
                return taxa[propriedade.substr(0, 8) + propriedade.substr(11, 30)] + taxaAntecipacao / 100 * 1.5;
            }
        } else if (propriedade.substr(0, 11).endsWith('3x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 8) + propriedade.substr(11, 30))) {
                return taxa[propriedade.substr(0, 8) + propriedade.substr(11, 30)] + taxaAntecipacao / 100 * 2.0;
            }
        } else if (propriedade.substr(0, 11).endsWith('4x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 8) + propriedade.substr(11, 30))) {
                return taxa[propriedade.substr(0, 8) + propriedade.substr(11, 30)] + taxaAntecipacao / 100 * 2.5;
            }
        } else if (propriedade.substr(0, 11).endsWith('5x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 8) + propriedade.substr(11, 30))) {
                return taxa[propriedade.substr(0, 8) + propriedade.substr(11, 30)] + taxaAntecipacao / 100 * 3.0;
            }
        } else if (propriedade.substr(0, 11).endsWith('6x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 8) + propriedade.substr(11, 30))) {
                return taxa[propriedade.substr(0, 8) + propriedade.substr(11, 30)] + taxaAntecipacao / 100 * 3.5;
            }
        }
    } else if (propriedade.startsWith('media7x12')) {
        if (propriedade.substr(0, 12).endsWith('7x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 9) + propriedade.substr(12, 30))) {
                return taxa[propriedade.substr(0, 9) + propriedade.substr(12, 30)] + taxaAntecipacao / 100 * 4.0;
            }
        } else if (propriedade.substr(0, 12).endsWith('8x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 9) + propriedade.substr(12, 30))) {
                return taxa[propriedade.substr(0, 9) + propriedade.substr(12, 30)] + taxaAntecipacao / 100 * 4.5;
            }
        } else if (propriedade.substr(0, 12).endsWith('9x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 9) + propriedade.substr(12, 30))) {
                return taxa[propriedade.substr(0, 9) + propriedade.substr(12, 30)] + taxaAntecipacao / 100 * 5.0;
            }
        } else if (propriedade.substr(0, 13).endsWith('10x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 9) + propriedade.substr(13, 30))) {
                return taxa[propriedade.substr(0, 9) + propriedade.substr(13, 30)] + taxaAntecipacao / 100 * 5.5;
            }
        } else if (propriedade.substr(0, 13).endsWith('11x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 9) + propriedade.substr(13, 30))) {
                return taxa[propriedade.substr(0, 9) + propriedade.substr(13, 30)] + taxaAntecipacao / 100 * 6.0;
            }
        } else if (propriedade.substr(0, 13).endsWith('12x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 9) + propriedade.substr(13, 30))) {
                return taxa[propriedade.substr(0, 9) + propriedade.substr(13, 30)] + taxaAntecipacao / 100 * 6.5;
            }
        }
    } else if (propriedade.startsWith('media13x21')) {
        if (propriedade.substr(0, 14).endsWith('13x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 7.0;
            }
        } else if (propriedade.substr(0, 14).endsWith('14x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 7.5;
            }
        } else if (propriedade.substr(0, 14).endsWith('15x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 8.0;
            }
        } else if (propriedade.substr(0, 14).endsWith('16x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 8.5;
            }
        } else if (propriedade.substr(0, 14).endsWith('17x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 9.0;
            }
        } else if (propriedade.substr(0, 14).endsWith('18x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 9.5;
            }
        } else if (propriedade.substr(0, 14).endsWith('19x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 10.0;
            }
        } else if (propriedade.substr(0, 14).endsWith('20x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 10.5;
            }
        } else if (propriedade.substr(0, 14).endsWith('21x')) {
            if (taxa.hasOwnProperty(propriedade.substr(0, 10) + propriedade.substr(14, 30))) {
                return taxa[propriedade.substr(0, 10) + propriedade.substr(14, 30)] + taxaAntecipacao / 100 * 11;
            }
        }
    }
    return 0;
};

export const alertaCampoObrigatorioSemi = (consulta, gerarTaxas) =>{
    if (!consulta.mcc || isNaN(parseInt(consulta.mcc))) {
        alert('MCC inválido. Por favor, preencher com um MCC válido.');
    } else if (!consulta.spreadDesejado || isNaN(parseFloat(consulta.spreadDesejado))) {
        alert('Spread inválido. Por favor, preencher com um spread válido.');
    } else if (!consulta.tpv || isNaN(parseInt(consulta.tpv))) {
        alert('TPV inválido. Por favor, preencher com um TPV válido.');
    } else if (consulta.taxaAntecipacao.replace(',', '.') < (consulta.noCusto ? 1 : 1.22) && consulta.taxaAntecipacao != 0) {
        alert(`Taxa de antecipação inválida. A taxa de antecipação mínima é de ${consulta.noCusto? '1,00' : '1,22'}. Deixe 0 caso o cliente seja não antecipado.`);
    } else {
        gerarTaxas();
    }
};

export const alertaCampoObrigatorioAuto = (consulta, gerarTaxas) => {
    if (!consulta.spreadDesejado || isNaN(parseFloat(consulta.spreadDesejado))) {
        alert('Spread inválido. Por favor, preencher com um spread válido.');
    } else if (!consulta.tpv || isNaN(parseInt(consulta.tpv))) {
        alert('TPV inválido. Por favor, preencher com um TPV válido.');
    }  else if (consulta.taxaAntecipacao.replace(',', '.') < (consulta.noCusto ? 1 : 1.22) && consulta.taxaAntecipacao != 0) {
        alert(`Taxa de antecipação inválida. A taxa de antecipação mínima é de ${consulta.noCusto? '1,00' : '1,22'}. Deixe 0 caso o cliente seja não antecipado.`);
    } else {
        gerarTaxas();
    }
};

export const alertaCampoObrigatorioLivre = (consulta, gerarTaxas) => {
    if (!consulta.mcc || isNaN(parseInt(consulta.mcc))) {
        alert('MCC inválido. Por favor, preencher com um MCC válido.');
    } else if (!consulta.tpv || isNaN(parseInt(consulta.tpv))) {
        alert('TPV inválido. Por favor, preencher com um TPV válido.');
    } else if (consulta.taxaAntecipacao.replace(',', '.') < (consulta.noCusto ? 1 : 1.22) && consulta.taxaAntecipacao != 0) {
        alert(`Taxa de antecipação inválida. A taxa de antecipação mínima é de ${consulta.noCusto? '1,00' : '1,22'}. Deixe 0 caso o cliente seja não antecipado.`);
    } else {
        gerarTaxas();
    }
};

export const alertaAlcada = (consulta, razaoSocial, spread) => {
    const usuario = localStorage.getItem('userName');
    const userDepartment = localStorage.getItem('department');
    const numberSpread = spread ? spread * 100 : 0;

    let alcada = null;
    if (!consulta.noCusto && ((userDepartment !== 'diretoria') && (userDepartment !== 'estrategiaeprodutos'))) {
        if (consulta.tpv <= 30000 && numberSpread < 0.9) {
            alcada = "alcada1";
            alert('Download indisponível devido à alçada. A tabela de taxa foi enviada, via email, para o líder do setor para aprovação. Para um TPV menor que R$ 30.000,00 o spread deve ser maior que 0,9%');
        } else  if (consulta.tpv >= 30000 && numberSpread < 0.45 && numberSpread > 0.15) {
            alcada = "alcada2";
            alert('Download indisponível devido à alçada. A tabela de taxa foi enviada, via email, para o líder do setor para aprovação. Para um TPV maior ou igual a R$ 30.000,00 o spread deve ser maior que 0,45%');
        } else if (consulta.tpv >= 30000 && numberSpread < 0.15) {
            alcada = "alcada3"
            alert('Download indisponível devido à alçada. A tabela de taxa foi enviada, via email, para o líder do setor para aprovação. Para um TPV maior ou igual a R$ 30.000,00 o spread deve ser maior que 0,45%');
        }
    }

    if (alcada) {
        const element = document.getElementById('tb-p');
        const opt = {
            margin: 0.5,
            filename: `TABELA-DE-TAXAS-${razaoSocial}.pdf`,
            image: {type: 'png', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
        };

        html2pdf().from(element).set(opt).outputPdf('blob').then((pdfBlob) => {
            const formData = new FormData();
            formData.append('alcada', alcada);
            formData.append('usuario', usuario)
            formData.append('tpv', consulta.tpv);
            formData.append('spread', numberSpread);
            formData.append('razaosocial', razaoSocial);
            formData.append('pdf', pdfBlob, `TABELA-DE-TAXAS-${razaoSocial}.pdf`);

            Axios.post('http://localhost:5000/precificador/gerar/alcada', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.error("Erro ao enviar a requisição:", error);
                });
        });
    } else {
        const element = document.getElementById('tb-p');
        const logos = document.getElementById('logo');
        const opt = {
            margin: 0.5,
            filename: `TABELA-DE-TAXAS-${razaoSocial}.pdf`,
            image: {type: 'png', quality: 0.98},
            html2canvas: {scale: 2},
            jsPDF: {unit: 'in', format: 'letter', orientation: 'portrait'}
        };
        html2pdf().from(element).set(opt).toImg(logos).save();
    }
};
