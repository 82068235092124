import {useState} from "react";
import './Precificador.css';
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import {useNavigate} from "react-router-dom";

function Precificador() {

    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="options">
                    <div className="">
                        <button className='option-btn' onClick={() => {
                            navigate('/precificador-auto')
                        }}>Precificador Automático
                        </button>
                    </div>
                    <div className="">
                        <button className='option-btn' onClick={() => {
                            navigate('/precificador-semi')
                        }}>Precificador Semi
                        </button>
                    </div>
                    <div className="">
                        <button className='option-btn' onClick={() => {
                            navigate('/precificador-livre')
                        }}>Precificador Livre
                        </button>
                    </div>
                </div>
                <div className="i-prec">
                    <h3>Precificador Automático:</h3>
                    <p>
                        Precificador que gera automaticamente as taxas apenas com o <b>CNPJ</b>, <b>Spread
                        desejado</b> e <b>Taxa de Antecipação.</b>
                    </p>
                    <h3>Precificador Semi-automático:</h3>
                    <p>
                        Veja todos os MCC's e escolha o que preferir.
                    </p>
                    <h3>Precificador Livre:</h3>
                    <p>
                        Precificador totalmente livre, podendo alterar as taxas manualmente.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Precificador;
