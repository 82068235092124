import SideHeader from "../../components/header/SideHeader";
import TopHeader from "../../components/header/TopHeader";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Forms.css';

function Logistica() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/formularios') }}>Voltar</button>
                </div>
                <iframe title="Formulário Pipefy" width='560' height='580' src='https://app.pipefy.com/public/form/-Yc-BS5P?embedded=true' frameborder='0' ></iframe>
            </div>
        </div>
    )
}
export default Logistica;