import '../../pages/calendar/Calendar.css';

function BirthdayModel(props) {
    var month = props.dt_nasc.substr(5, 2);
    var day = props.dt_nasc.substr(8, 2);

    return (
        <div className="birthdayModelContainer">
            <p id="birthdayModelContent">{props.name}: {day.concat(" - ", month)}</p>
            <br></br>
        </div>
    )
}

export default BirthdayModel;