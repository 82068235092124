import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Relatorio.css';

function Marketing() {
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => { navigate('/relatorios') }}>Voltar</button>
                </div>
                <br />
                
            </div>
        </div>
    )
}

export default Marketing;