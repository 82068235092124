import Axios from 'axios';
import SideHeader from "../../components/header/SideHeader";
import TopHeader from "../../components/header/TopHeader";
import {useState} from "react";

function CancelPayment() {
    const [consulta, setConsulta] = useState({
        paymentId: '',
        valueTxOri: '',
        cancelAmount: '',
        cancelObservations: '',
    })

    const handleSubmit = async () => {
        const response = await Axios.post('http://localhost:5000/cancelPayment', {
            paymentId: consulta.paymentId,
            valueTxOri: consulta.valueTxOri,
            cancelAmount: consulta.cancelAmount,
            cancelObservations: consulta.cancelObservations,
        });

        try {
            console.log(response);
        } catch (e) {
            console.log(e);
        }
    }

    const handleChange = (e) => {
        setConsulta({...consulta, [e.target.name]: e.target.value});
    };


    return (
        <div>
            <TopHeader/>
            <h1>Cancelar transação</h1>
            <input type="text" name="paymentId" value={consulta.paymentId} onChange={handleChange} required/>
            <input type="text" name="valueTxOri" value={consulta.valueTxOri} onChange={handleChange} required/>
            <input type="text" name="cancelAmount" value={consulta.cancelAmount} onChange={handleChange} required/>
            <input type="text" name="cancelObservations" value={consulta.cancelObservations} onChange={handleChange}
                   required/>
            <button onClick={handleSubmit}>TESTAR</button>
        </div>
    )
}

export default CancelPayment;
