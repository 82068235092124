import { isExpired, decodeToken } from "react-jwt";
import Axios from 'axios';

function tokenAuthentication() {
    var accessToken = localStorage.getItem('accessToken');
    var msg = null;

    if (accessToken === null) {
        return false;
    };

    const myDecodedToken = decodeToken(accessToken);

    const checkLogin = async () => {
        // Envia uma requisição para a url com os dados do user
       await Axios.post(process.env.REACT_APP_CLIENT_CHECK_LOGIN, {
            email: myDecodedToken.email,
            password: myDecodedToken.password
        }).then((response) => {
            msg = response.data.msg;
            if (msg === 'userChecked') {
                return true;
            } else {
                if (msg === 'userNotFound') alert('Não foi possível autenticar o usuário.');
                return false;
            }
        })
    };
    
    const isMyTokenExpired = isExpired(accessToken);
    if(!isMyTokenExpired && checkLogin()) {
        return true;
    };
    return false;
}

export default tokenAuthentication;
