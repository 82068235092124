import React from 'react'
import SideHeader from '../../components/header/SideHeader';
import TopHeader from '../../components/header/TopHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Forms.css';

function Formularios() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="options">
                    <div className="">
                        <button className='option-btn' onClick={() => { navigate('/bem-estar') }}>Formulário de bem estar</button>
                    </div>
                    <div className="">
                        <button className='option-btn' onClick={() => { navigate('/logistica') }}>Formulário logistica</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Formularios