import { Navigate } from "react-router-dom";
import tokenAuthentication from '../authentication/tokenAuthentication';

export const PrivateRoute = ({ children }) => {

  if (tokenAuthentication())
    return children;

  return <Navigate to="/login" />
}


