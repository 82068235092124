import {AiOutlineLoading} from "react-icons/ai";
import './Loading.css';

function Loading () {

    return (
        <div>
            <p>Loading... <AiOutlineLoading  className="loading-icon"/></p>
        </div>
    )
}

export default Loading;