
function NotFound() {

    return (
        <div>
            <h1>Página não encontrada. Erro 404</h1>  
        </div>
    )
}

export default NotFound;