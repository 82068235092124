import { useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopHeader from '../../components/header/TopHeader';
import SideHeader from '../../components/header/SideHeader';
import './Profile.css';

function DeleteProfile() {

    var msg = null;
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    const [consulta, setConsulta] = useState({
        email: ''
    });
    const deleteUser = async () => {
        await Axios.delete(process.env.REACT_APP_CLIENT_DELETE_USER, {
            params: {
                email: consulta.email
            }
        }).then((response) => {
            msg = response.data.msg;
            if (msg === 'userNotFound') alert('Usuário não encontrado.');
            if (msg === 'userDeleted') alert('Usuário excluído com sucesso.');
        })
    };
    const handleInput = (e) => {
        setConsulta({ ...consulta, [e.target.name]: e.target.value })
    };

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />

            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className='update_profile_page'>
                    <p>Escreva o email do usuário que deseja excluir</p>
                    <input id='userInput' type="mail" name="email" placeholder='Email' value={consulta.email} onChange={handleInput}></input>
                    <button id='del_btn' onClick={deleteUser}>Excluir</button>
                </div>
            </div>
        </div>
    )
}
export default DeleteProfile;