import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Academia.css';

function VideosFinanceiro() {
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton_geral" onClick={() => { navigate('/academia') }}>Voltar</button>
                </div>
                <h1>FINANCEIRO</h1>
                <h3>01 - Introdução - Estoque pt. 1</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/BlRWPeL_248" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>01 - Estoque pt. 2</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/ePxMODfmO-U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>02 - Compras</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/5j0bhULr5CQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>03 - Drive Compartilhamento</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/EvjbToRYQT0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>04 - Orçamento</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/ZntTugvvLA4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>05 - Pagamento via Arquivo Valori</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/wjEK1lzANGQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>06 - Planilha</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/yooVP4-_fSc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>07 - Planilha SCV</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/xFhJWMuSLfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>08 - Fatura do Cartão</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/L3VyLYpiQIQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
            </div>
        </div>
    )
}
export default VideosFinanceiro;