import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import './Relatorio.css';
import {useNavigate} from 'react-router-dom';
import LineChart from "../../components/LineChart";
import {useEffect, useState} from "react";
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Axios from "axios";
import {TPVData} from "../../resources/Data";

function Relatorio() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    const [selectedYear, setSelectedYear] = useState(2024);
    const [tpvDataYear, setTpvDataYear] = useState([]);
    const [tpvAverageDataYear, setTpvAverageDataYear] = useState([]);
    const department = localStorage.getItem('department');
    let allMonths = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value, 10));
    };

    const [chartData, setChartData] = useState({
        labels: allMonths,
        datasets: [{
            label: 'TPV em milhões',
            data: tpvDataYear,
            borderColor: 'rgb(15, 44, 40)',
            borderWidth: 2,
            tension: 0.15,
            fill: true,
            backgroundColor: 'rgba(207, 155, 80, 0.4)',
            plugins: [ChartDataLabels],
            datalabels: {
                color: 'black',
                align: ['end'],
                offset: 10,
                font: {
                    weight: 'regular',
                }
            },
        },
            {
                label: 'TPV diário em milhões',
                data: tpvAverageDataYear,
                borderColor: 'rgb(15, 44, 40)',
                borderWidth: 2,
                tension: 0.15,
                fill: true,
                backgroundColor: 'rgb(80, 207, 91, 0.75)',
                plugins: [ChartDataLabels],
                datalabels: {
                    color: 'black',
                    align: ['end'],
                    offset: 5,
                    font: {
                        weight: 'regular',
                    }
                },
            }
        ]
    });

    const [chartOptions, setChartOptions] = useState({
        responsive: true,
        //maintainAspectRatio: false,
        scales: {
            y: {
                ticks: {
                    callback: function (value) {
                        return "R$  " + value + " Mi";
                    },
                },
            },
        },
        plugins: {
            subtitle: {
                display: true,
                text: 'Total Payment Volume (Volume Total de Pagamentos)',
            },
        }
    });

    Chart.register(ChartDataLabels);

    useEffect(() => {
        Axios.get(process.env.REACT_APP_CLIENT_CHART_GET).then((response) => {
            let tempTpvDataYear = [];
            let tempTpvAverageDataYear = [];

            for (let x in response.data) {
                if (response.data[x].ano == selectedYear) {
                    tempTpvDataYear.push(response.data[x].tpv);
                    tempTpvAverageDataYear.push(response.data[x].media);
                }
            }

            setTpvDataYear(tempTpvDataYear);
            setTpvAverageDataYear(tempTpvAverageDataYear);
        });
    }, [selectedYear]);

    useEffect(() => {
        setChartData({
            labels: allMonths,
            datasets: [{
                label: 'TPV em milhões',
                data: tpvDataYear,
                borderColor: 'rgb(15, 44, 40)',
                borderWidth: 2,
                tension: 0.15,
                fill: true,
                backgroundColor: 'rgba(207, 155, 80, 0.4)',
                plugins: [ChartDataLabels],
                datalabels: {
                    color: 'black',
                    align: ['end'],
                    offset: 10,
                    font: {
                        weight: 'regular',
                    }
                },
            },
                {
                    label: 'TPV diário em milhões',
                    data: tpvAverageDataYear,
                    borderColor: 'rgb(15, 44, 40)',
                    borderWidth: 2,
                    tension: 0.15,
                    fill: true,
                    backgroundColor: 'rgb(80, 207, 91, 0.75)',
                    plugins: [ChartDataLabels],
                    datalabels: {
                        color: 'black',
                        align: ['end'],
                        offset: 5,
                        font: {
                            weight: 'regular',
                        }
                    },
                }
            ]
        });
    }, [tpvDataYear, tpvAverageDataYear]);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className='departmentList'>
                    <button id='departmentButtonGeral' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/geral')
                    }}>Geral
                    </button>
                    <button id='departmentButtonEstrategia' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/diretoria')
                    }}>Diretoria
                    </button>
                    <button id='departmentButtonAtendimento' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/atendimento')
                    }}>Atendimento
                    </button>
                    <button id='departmentButtonOperacional' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/operacional')
                    }}>Operacional
                    </button>
                    <button id='departmentButtonComercial' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/comercial')
                    }}>Comercial
                    </button>
                    <button id='departmentButtonFinanceiro' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/financeiro')
                    }}>Financeiro
                    </button>
                    <button id='departmentButtonMarketing' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/marketing')
                    }}>Marketing
                    </button>
                    <button id='departmentButtonRH' className='departmentButton' onClick={(e) => {
                        navigate('/relatorios/rh')
                    }}>RH
                    </button>
                </div>
                <div>
                    Filtrar ano:
                    <select value={selectedYear} onChange={handleYearChange} className='tpv-filter'>
                        <option value={2023}>2023</option>
                        <option value={2024}>2024</option>
                    </select>
                </div>
                <div className="chart">
                    <h3>TPV Mensal</h3>
                    <LineChart chartData={chartData} chartOptions={chartOptions}/>
                </div>
                {department === 'estrategiaeprodutos' ? <button onClick={() => {
                    navigate('/relatorios/update');
                }}>Atualizar
                </button> : <div></div>}

            </div>
        </div>
    )
}

export default Relatorio;
