import './Login.css';
import { useState, React } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import loginLogo from '../../images/loginLogo.png';
import { FaLock } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import PoliticaPrivacidadeValori from '../../resources/files/PoliticaPrivacidadeValori.pdf'

function Login() {

    var accessToken, msg, department, userName, email, role = null;
    const [user, setUser] = useState({
        email: "",
        password: "",
        department: "",
        userName: "",
        dt_nasc: "",
        role: ""
    });
    const [passwordShown, setPasswordShown] = useState(false);
    const navigate = useNavigate();
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const handleInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };
    const preventSubmit = (e) => {
        e.preventDefault();
    };

    // Função para fazer o login
    const handleSubmit = async () => {
        await Axios.post(process.env.REACT_APP_CLIENT_LOGIN, {
            email: user.email,
            password: user.password
        }).then((response) => {
            if (response.data.status === true) {
                accessToken = response.data.accessToken;
                department = response.data.department;
                userName = response.data.userName;
                email = response.data.email;
                role = response.data.role;

                localStorage.setItem('accessToken', accessToken);
                localStorage.setItem('email', email);
                localStorage.setItem('department', department);
                localStorage.setItem('userName', userName);
                localStorage.setItem('role', role);
                navigate('/bem-estar');
            } else {
                msg = response.data.msg;
                if (msg === 'wrongPassword') alert('Senha incorreta. Verifique seus dados e tente novamente.');
                if (msg === 'wrongEmail') alert('Usuário não encontrado. Verifique seus dados e tente novamente.');
            }
        })
    };

    // Função para criar 
    const CriarContaAlert = () => {
        alert('Somente o time de Estratégia e Produtos disponibiliza o acesso ao ValoriDash. Portanto, entre em contato com o mesmo para ter acesso!');
    };

    return (
        <div id="loginMain">
            <img alt='Logo valoridash' src={loginLogo} id='login_logo' />
            <div id="loginFormContainer">
                <form method="post" onSubmit={preventSubmit} id="textbox" >

                    <div id='icon_container'>
                        <MdEmail color='rgb(183,158,135)' size={20} />
                        <input type="email" name="email" placeholder="E-mail" value={user.email} onChange={handleInput} required />
                    </div>
                    <div id='icon_container'>
                        <FaLock color='rgb(183,158,135)' size={20} />
                        <input type={passwordShown ? 'text' : 'password'} name="password" placeholder="Senha" value={user.password} onChange={handleInput} required />
                        <div onClick={togglePasswordVisiblity}>
                            <AiOutlineEyeInvisible color='white' size={25} />
                        </div>
                    </div>
                    <br /><br />
                    <button className='btn_entrar' onClick={handleSubmit}>Entrar</button>
                    
                </form>
            </div>
            <div id='loginFooter'>
                <a onClick={CriarContaAlert}>Como cria uma conta</a>
                <br></br>
                <a href={PoliticaPrivacidadeValori} target="_blank" >Política de privacidade</a>
                <br></br>
                <a href='/confirmEmail' target="_blank" >Esqueci minha senha</a>
            </div>
        </div>
    )
}
export default Login; 