import './TopHeader.css';
import { useNavigate } from 'react-router-dom';

function TopHeader() {
    const navigate = useNavigate();
    const name = localStorage.getItem('userName');
    const pf = (name) => {  
        if( name === 'Eduardo Carvalho'){
            return 'header_user_edu';
        }else if( name ==='Arthur Costa'){
            return 'header_user_costa';
        }if( name === 'Arthur Bernardo'){
            return 'header_user_bernardo';
        }
        else {
            return 'header_user';
        }
    }
    
    return (
        <div className='upperHeader'>
            <li>
                <p id='headerText'>Bem vindo ao ValoriDash, {localStorage.getItem('userName')}!</p>
                <a className='b-e' onClick={() => {navigate('/bem-estar')}}>Como você está hoje?</a>
                <div className='profile_faq'>
                    <a aria-label='FAQ' title='FAQ' onClick={() => { navigate('/faq') }}><div className='header_faq'></div></a>
                    <a id='profileHeader' aria-label='Perfil' title='Perfil' onClick={() => { navigate('/profile') }}>
                        <h6 id='nome'>{localStorage.getItem('userName')}</h6>
                        <div className= { pf(name) }></div>
                    </a>
                </div>
            </li>
        </div>
    )
}
export default TopHeader;