import Axios from "axios";
import { useEffect, useState } from "react";
import NewsModel from "../../components/NewsModel";
import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import './News.css';

function News() {

    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [listNews, setListNews] = useState('');

    useEffect( () => {
        setIsLoading(true);
         Axios.get(process.env.REACT_APP_CLIENT_NEWS).then((response) => {
            setListNews(response.data);
            setIsLoading(false);
        })
    }, []);

    return (
        <div id='news'>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <button class="addButton" onClick={() => { navigate('/register/news') }}>Cadastar notícia</button>
                {isLoading ? <Loading /> : <div className='newsContaienr'>
                    {Array.from(listNews).sort((a, b) => (a.dt_creation < b.dt_creation) ? 1 : ((b.dt_creation < a.dt_creation) ? -1 : 0)).map((news, index) => {
                        return <NewsModel
                            id={news.id}
                            title={news.title}
                            content={news.content}
                            dt_creation={news.dt_creation}
                            author={news.author}
                            key={index}></NewsModel>
                    })}
                </div>}
            </div>
        </div>
    )
}

export default News;