import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import './Academia.css';

function VideosOperacional(){
    const [navVisible, showNavbar] = useState(false);
    const navigate = useNavigate();

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar} />
            <TopHeader />
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton_geral" onClick={() => { navigate('/academia') }}>Voltar</button>
                </div>
                <h1>Operacional</h1>
                <h3>01 - Introdução</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/PQVRcJsKU_c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>02 - Solicitações Operacionais</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/DoWPIdJciAU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>03 - Logística</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/4fr-52rH7sc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>04 - Coordenador</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/4RumVNK6DHo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
                <h3>05 - Agente de Entrega</h3>
                <iframe className='videoFrame' width="950" height="534" src="https://www.youtube.com/embed/H56_z6CVvtY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen='true'></iframe>
            </div>
        </div>
    )
}
export default VideosOperacional;