import React from 'react';
import './Analyzes.css'
import { BsArrowDownCircle } from 'react-icons/bs';

function PdfViewerModel(props) {

    const expandir = () => {
        var elementId = 'pdfViewerModel' + props.id;
        var className = document.getElementById(elementId).className;
        if (className === 'hidden') document.getElementById(elementId).className = '';
        else if (className === '') document.getElementById(elementId).className = 'hidden';
    };

    return (
        <div className='analyzesContainer'>
            <button className='pdfButton' onClick={expandir}><BsArrowDownCircle size={12} /> Abrir</button>
            <div className='pdfTitle'>
                <p>{props.title}</p>
            </div>
            <div className='pdfDescription'>
                <p>{props.description}</p>
            </div>
            <object id={'pdfViewerModel' + props.id} className="hidden" data={props.link} type="application/pdf" width="100%" height="600">
                <p>Alternative text - include a link <a href={props.link}>to the PDF!</a></p>
            </object>
            <div className='pdfDate'>
                <p>{props.date.split('-').reverse().join('/')}</p>
            </div>
            <div className='pdfAuthor'>
                <p>{props.author}</p>
            </div>
        </div>
    )
}

export default PdfViewerModel;
