import { decodeToken } from "react-jwt";
import { Navigate } from "react-router-dom";

function departmentAuthentication() {

    var accessToken = localStorage.getItem('accessToken');
    var department = localStorage.getItem('department');

    const myDecodedToken = decodeToken(accessToken);

    if(myDecodedToken.department === department) {
        return true;
    } else {
        alert('Não foi possível autenticar o departamento do usuário!');
        return false;
    }
}

export default departmentAuthentication;
